import React, { useState, useEffect } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandlogo from "../../images/brandlogo.png";
import viewIcon from "../../images/view.png";
import createfolder from "../../images/createfolder.png";
import ReactPaginate from "react-paginate";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import back from "../../images/back.png";
import { useHistory } from "react-router-dom";
import { getUserID } from "../../utils/Common";
import deleteIcon from "../../images/deleteicon.png";
import editIcon from "../../images/editicon.png";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const AdminViewFoldersOfClient = ({ match }, props) => {
  const id = getUserID();
  let history = useHistory();
  const [fullname, setFullName] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(false);

  const [ascending, setAscending] = useState(false);

  const sortByDate = () => {
    if (ascending === true) {
      const sorted = folders.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setFolders(sorted);
    } else {
      const sorted = folders.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
      setFolders(sorted);
    }
  };

  const sortfiles = () => {
    setAscending(!ascending);
    sortByDate();
  };

  const getFolders = async () => {
    const details = await fetch(
      `https://vetacpav2.azurewebsites.net//api/folder/user/${match.params.id}`,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      }
    );
    const data = await details.json();
    setFolders(data);
  };

  useEffect(() => {
    getFolders();

    const getUsers = async () => {
      const details = await fetch(
        `https://vetacpav2.azurewebsites.net//api/customermaster/client/view/${match.params.id}`,
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },
        }
      );
      const data = await details.json();
      setFullName(`${data.FirstName} ${data.LastName}`);
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteFolder = (e, value) => {
    let folderid = e.currentTarget.value;
    //delete folder
    const deleteFolderApi = async () => {
      await fetch(
        `https://vetacpav2.azurewebsites.net//api/folder/delete/${folderid}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "user-id": id,
            "content-type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message) {
            setLoading(false);
            toast.error(responseJson.message);
          } else if (responseJson.success) {
            setLoading(false);
            toast.success(responseJson.success);
            getFolders();
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.message);
        });
    };
    Swal.fire({
      title: `\n Are You Sure To Delete The ${value} Folder And It's Contents..?`,
      text: ``,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: ``,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true);
        deleteFolderApi();
      } else {
        setLoading(false);
      }
    });
  };

  const foldersPerPage = 10;
  const pageVisited = pageNumber * foldersPerPage;
  const pageCount = Math.ceil(folders.length / foldersPerPage);

  const displayFolders = folders
    .slice(pageVisited, pageVisited + foldersPerPage)
    .map((folder) => {
      const date = format(new Date(folder.createdAt), "MM-dd-yyyy");
      return (
        <tr>
          <td>{folder.FolderName}</td>
          <td>{date}</td>
          <td>
            <Link to={`/files/${folder._id}`}>
              <img
                src={viewIcon}
                alt="view_icon"
                className="ml-3"
                width="21"
                height="18"
              />
            </Link>
          </td>
          <td>
            <Link to={`/admin/manageclient/viewfolders/edit/${folder._id}`}>
              <img
                src={editIcon}
                alt="view_icon"
                className="ml-3"
                width="21"
                height="18"
              />
            </Link>
          </td>
          <td>
            <button
              type="button"
              className="action_btn deleteData"
              title="Delete"
              onClick={(e) => {
                deleteFolder(e, `${folder.FolderName}`);
              }}
              value={folder._id}
            >
              <img
                src={deleteIcon}
                alt="delete-icon"
                className="ml-3"
                width="14"
                height="18"
              />
            </button>
          </td>
        </tr>
      );
    });

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  if (folders.length <= 0) {
    return (
      <div id="main-content">
        <main className="dashBoard" id="main-wrapper">
          <div className="opacity_bg"></div>
          <div className="container-fluid">
            <div className="content">
              <div className="row">
                <DashBoardLeft />
                <div className="col-md-12 col-lg-9">
                  <div className="card card-border-radius">
                    <div className="card-header position-relative">
                      {/*button to toggle sidebar for smaller width*/}
                      <div className="toggle-icon-wrapper">
                        <button
                          className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                          id="navbar_humburger"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          title="navbar_humburger"
                          data-bs-original-title="Toggle Navigation"
                          aria-label="Toggle Navigation"
                        >
                          <span className="navbar-toggle-icon">
                            <span className="toggle-line"></span>
                          </span>
                        </button>
                      </div>

                      {/*Brand Logo Images*/}
                      <img
                        src={brandlogo}
                        width="585"
                        height="113"
                        alt="Veta & Association Logo"
                        className="logo"
                      />
                    </div>

                    <div className="card-body">
                      <div className="row justify-content-between">
                        <div className="col-6"></div>
                        <div className="col-6 d-flex justify-content-end">
                          <img
                            src={back}
                            alt="back"
                            style={{ width: "10%", cursor: "pointer" }}
                            onClick={history.goBack}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <h5
                            style={{ color: "#940603" }}
                          >{`Home >>>  ${fullname}`}</h5>
                        </div>
                      </div>
                      <div className="row">
                        <h1 className="mx-auto b pt-2">Nothing Found</h1>
                      </div>
                      <div className="row pt-5 pb-5">
                        <div className="col-12 mx-auto text-center">
                          <Link
                            to={`/admincreatefolder/${match.params.id}`}
                            type="button"
                            className="action_btn editData mr-3"
                            title="Create Folder"
                          >
                            <img
                              src={createfolder}
                              alt="create-folder"
                              width="64"
                              height="69"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  } else {
    return (
      <div id="main-content">
        <main className="dashBoard" id="main-wrapper">
          <div className="opacity_bg"></div>
          <div className="container-fluid">
            <div className="content">
              <div className="row">
                <DashBoardLeft />
                <div className="col-md-12 col-lg-9">
                  <div className="card card-border-radius">
                    <div className="card-header position-relative">
                      {/*button to toggle sidebar for smaller width*/}
                      <div className="toggle-icon-wrapper">
                        <button
                          className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                          id="navbar_humburger"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          title="navbar_humburger"
                          data-bs-original-title="Toggle Navigation"
                          aria-label="Toggle Navigation"
                        >
                          <span className="navbar-toggle-icon">
                            <span className="toggle-line"></span>
                          </span>
                        </button>
                      </div>

                      {/*Brand Logo Images*/}
                      <img
                        src={brandlogo}
                        width="585"
                        height="113"
                        alt="Veta & Association Logo"
                        className="logo"
                      />
                    </div>

                    <div className="card-body">
                      <div className="row justify-content-between">
                        <div className="col-6"></div>
                        <div className="col-6 d-flex justify-content-end">
                          <img
                            src={back}
                            alt="back"
                            style={{ width: "10%", cursor: "pointer" }}
                            onClick={history.goBack}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <h5
                            style={{ color: "#940603" }}
                          >{`Home >>>  ${fullname}`}</h5>
                        </div>
                        <div className="col-6 d-flex justify-content-end mr-5">
                          <Link
                            to={`/admincreatefolder/${match.params.id}`}
                            type="button"
                            className="action_btn editData mr-3"
                            title="Create Folder"
                          >
                            <img
                              src={createfolder}
                              alt="create-folder"
                              width="64"
                              height="69"
                            />
                          </Link>
                        </div>
                      </div>

                      <div className="table-responsive">
                        <table
                          className="table table-vcenter"
                          id="dataTable_files"
                        >
                          <thead>
                            <tr>
                              <th style={{ width: "20%" }}>Folder Name</th>
                              <th
                                style={{ width: "15%", cursor: "pointer" }}
                                onClick={sortfiles}
                              >
                                Created Date <span>&#8595;</span>
                                <span>&#8593;</span>
                              </th>
                              <th style={{ width: "15%" }}>View</th>
                              <th style={{ width: "15%" }}>Edit</th>
                              <th style={{ width: "15%" }}>Delete</th>
                            </tr>
                          </thead>
                          <tbody>{displayFolders}</tbody>
                        </table>
                        <div className="table_nav">
                          <ReactPaginate
                            previousLabel="Previous"
                            nextLabel="Next"
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
};

export default AdminViewFoldersOfClient;
