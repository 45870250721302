import React from "react";
import Signup from "../../images/signup.jpg";

const LeftImage = () => {
  return (
    <div className="col-lg-6 col-md-6 p-0 d-none d-md-block">
      <div className="bg-cover h-100 me-n3 position-relative">
        <div className="slider-container">
          <div className="sliders" id="sliders">
            <div className="slide-item">
              <img alt="Signup Bg" src={Signup} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftImage;
