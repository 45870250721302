import React, { useState, useEffect } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandlogo from "../../images/brandlogo.png";
import { toast } from "react-toastify";
import { getToken } from "../../utils/Common";

const AdminSettings = () => {
  const [loading, setLoading] = useState(false);
  const [extensions, setExtensions] = useState([]);
  const [settings, setSettings] = useState([]);
  const token = getToken();

  useEffect(() => {
    const getSettings = async () => {
      const details = await fetch(
        `https://vetacpav2.azurewebsites.net/api/settings/615e78ae7f696764418f1b55`,
        {
          headers: {
            Accept: "application/json",
            "auth-token": token,
            "content-type": "application/json",
          },
        }
      );
      const data = await details.json();
      await setSettings(data);
      await setExtensions(data.FileExtension);
    };
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  const togglearray = (e) => {
    extensions.includes(e.target.value)
      ? setExtensions(extensions.filter((i) => i !== e.target.value))
      : setExtensions([...extensions, e.target.value]);
  };

  const updateSettings = async () => {
    const data = await {
      FileSize: settings.FileSize,
      FileExtension: extensions,
    };
    await fetch(
      `https://vetacpav2.azurewebsites.net/api/settings/615e78ae7f696764418f1b55`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "auth-token": token,
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message) {
          setLoading(false);
          toast.error(responseJson.message);
        } else {
          setLoading(false);
          toast.success(responseJson.success);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    updateSettings();
  };

  return (
    <div id="main-content">
      <main className="dashBoard" id="main-wrapper">
        <div className="opacity_bg"></div>
        <div className="container-fluid">
          <div className="content">
            <div className="row">
              <DashBoardLeft />
              <div className="col-md-12 col-lg-9">
                <div className="card card-border-radius">
                  <div className="card-header position-relative">
                    <div className="toggle-icon-wrapper">
                      <button
                        className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                        id="navbar_humburger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="navbar_humburger"
                        data-bs-original-title="Toggle Navigation"
                        aria-label="Toggle Navigation"
                      >
                        <span className="navbar-toggle-icon">
                          <span className="toggle-line"></span>
                        </span>
                      </button>
                    </div>

                    <img
                      src={brandlogo}
                      width="585"
                      height="113"
                      alt="Veta & Association Logo"
                      className="logo"
                    />
                  </div>

                  <div className="card-body">
                    <h2 className="settingTitle">Settings</h2>

                    <form onSubmit={submitHandler} id="uploadSettingsForm">
                      <div className="row justify-content-around">
                        <div className="col-sm-12 col-md-6 fileSettings">
                          <div className="form-group position-relative">
                            <label for="maxFileSize">Maximum file size :</label>
                            <input
                              type="text"
                              name="FileSize"
                              value={settings.FileSize}
                              id="maxFileSize"
                              className="form-control"
                              onChange={handleChange}
                            />
                            <span className="fileSize_text">MB</span>
                          </div>
                          <h3>Extensions</h3>
                          <p className="tagline">(check all / uncheck all)</p>

                          <div className="checkbox_group">
                            <div className="checkbox_item">
                              <div className="form-check text-center position-relative">
                                <input
                                  className="form-check-label"
                                  checked={
                                    extensions.includes("application/msword")
                                      ? true
                                      : false
                                  }
                                  value="application/msword"
                                  name="FileExtension"
                                  type="checkbox"
                                  onChange={togglearray}
                                  id="docCheckbox"
                                />
                                <label
                                  className="form-check-label position-relative"
                                  for="docCheckbox"
                                >
                                  .doc
                                </label>
                              </div>
                            </div>
                            <div className="checkbox_item">
                              <div className="form-check text-center position-relative">
                                <input
                                  className="form-check-label"
                                  checked={
                                    extensions.includes("application/pdf")
                                      ? true
                                      : false
                                  }
                                  value="application/pdf"
                                  name="FileExtension"
                                  type="checkbox"
                                  onChange={togglearray}
                                  id="pdfCheckbox"
                                />
                                <label
                                  className="form-check-label position-relative"
                                  for="pdfCheckbox"
                                >
                                  .pdf
                                </label>
                              </div>
                            </div>
                            <div className="checkbox_item">
                              <div className="form-check text-center position-relative">
                                <input
                                  className="form-check-label"
                                  checked={
                                    extensions.includes(
                                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    )
                                      ? true
                                      : false
                                  }
                                  value="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  name="FileExtension"
                                  type="checkbox"
                                  onChange={togglearray}
                                  id="docxCheckbox"
                                />
                                <label
                                  className="form-check-label position-relative"
                                  for="docxCheckbox"
                                >
                                  .docx
                                </label>
                              </div>
                            </div>
                            <div className="checkbox_item">
                              <div className="form-check text-center position-relative">
                                <input
                                  className="form-check-label"
                                  checked={
                                    extensions.includes(
                                      "application/vnd.ms-excel"
                                    )
                                      ? true
                                      : false
                                  }
                                  value="application/vnd.ms-excel"
                                  name="FileExtension"
                                  type="checkbox"
                                  onChange={togglearray}
                                  id="xlsCheckbox"
                                />
                                <label
                                  className="form-check-label position-relative"
                                  for="xlsCheckbox"
                                >
                                  .xls
                                </label>
                              </div>
                            </div>
                            <div className="checkbox_item">
                              <div className="form-check text-center position-relative">
                                <input
                                  className="form-check-label"
                                  checked={
                                    extensions.includes(
                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    )
                                      ? true
                                      : false
                                  }
                                  value="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  name="FileExtension"
                                  type="checkbox"
                                  onChange={togglearray}
                                  id="xlsxCheckbox"
                                />
                                <label
                                  className="form-check-label position-relative"
                                  for="xlsxCheckbox"
                                >
                                  .xlsx
                                </label>
                              </div>
                            </div>
                            <div className="checkbox_item">
                              <div className="form-check text-center position-relative">
                                <input
                                  className="form-check-label"
                                  checked={
                                    extensions.includes("text/plain")
                                      ? true
                                      : false
                                  }
                                  value="text/plain"
                                  name="FileExtension"
                                  type="checkbox"
                                  onChange={togglearray}
                                  id="txtCheckbox"
                                />
                                <label
                                  className="form-check-label position-relative"
                                  for="txtCheckbox"
                                >
                                  .txt
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        id="updateUserBtn"
                        className="btn btn-block button-primary"
                        disabled={loading ? true : false}
                      >
                        {loading ? "Loading..." : "Update Settings"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminSettings;
