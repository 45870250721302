import React, { useState, useEffect } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandlogo from "../../images/brandlogo.png";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { getToken } from "../../utils/Common";
import back from "../../images/back.png";

const AdminEditSingleCPA = ({ match }, props) => {
  let history = useHistory();
  const token = getToken();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const showpass = (e) => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const getCpaDetails = async () => {
      const details = await fetch(
        `https://vetacpav2.azurewebsites.net/api/cpa/${match.params.id}`,
        {
          headers: {
            Accept: "application/json",
            "auth-token": token,
            "content-type": "application/json",
          },
        }
      );
      const data = await details.json();
      setData(data);
    };
    getCpaDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateApi = async () => {
    await fetch(
      `https://vetacpav2.azurewebsites.net/api/cpa/${match.params.id}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "auth-token": token,
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message) {
          setLoading(false);
          toast.error(responseJson.message);
        } else if (responseJson.success) {
          setLoading(false);
          toast.success(responseJson.success);
          history.goBack();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  const formHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    updateApi();
  };

  return (
    <div id="main-content">
      <main className="dashBoard" id="main-wrapper">
        <div className="opacity_bg"></div>
        <div className="container-fluid">
          <div className="content">
            <div className="row">
              <DashBoardLeft />
              <div className="col-md-12 col-lg-9">
                <div className="card card-border-radius">
                  <div className="card-header position-relative">
                    <div className="toggle-icon-wrapper">
                      <button
                        className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                        id="navbar_humburger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="navbar_humburger"
                        data-bs-original-title="Toggle Navigation"
                        aria-label="Toggle Navigation"
                      >
                        <span className="navbar-toggle-icon">
                          <span className="toggle-line"></span>
                        </span>
                      </button>
                    </div>
                    <img
                      src={brandlogo}
                      width="585"
                      height="113"
                      alt="Veta & Association Logo"
                      className="logo"
                    />
                  </div>

                  <div className="card-body">
                    <div className="row justify-content-between">
                      <div className="col-6"></div>
                      <div className="col-6 d-flex justify-content-end">
                        <img
                          src={back}
                          alt="back"
                          style={{ width: "10%", cursor: "pointer" }}
                          onClick={history.goBack}
                        />
                      </div>
                    </div>
                    <h4 className="text-center">
                      Edit {data.FirstName} {data.LastName} &nbsp;
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="31.411"
                          height="25.13"
                          viewBox="0 0 31.411 25.13"
                        >
                          <path
                            id="Icon_awesome-user-edit"
                            data-name="Icon awesome-user-edit"
                            d="M10.994,12.565A6.282,6.282,0,1,0,4.712,6.282,6.282,6.282,0,0,0,10.994,12.565Zm4.4,1.571h-.82a8.544,8.544,0,0,1-7.156,0H6.6a6.6,6.6,0,0,0-6.6,6.6v2.042A2.357,2.357,0,0,0,2.356,25.13H15.849a2.357,2.357,0,0,1-.128-1.045l.334-2.989.059-.545.388-.388L20.3,16.369a6.53,6.53,0,0,0-4.9-2.233Zm2.223,7.132-.334,2.994a.781.781,0,0,0,.864.864l2.989-.334L27.9,18.023,24.384,14.5l-6.768,6.763ZM31.069,13.2l-1.86-1.86a1.176,1.176,0,0,0-1.659,0l-1.855,1.855-.2.2,3.524,3.519,2.052-2.052a1.182,1.182,0,0,0,0-1.664Z"
                            fill="#b30000"
                          />
                        </svg>
                      </span>
                    </h4>
                    <br />
                    <form onSubmit={formHandler} id="edit_profile_form">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="form-group position-relative">
                            <label for="username">First Name</label>
                            <input
                              type="text"
                              name="FirstName"
                              id="firstname"
                              className="form-control"
                              placeholder="User name "
                              value={data.FirstName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5">
                          <div className="form-group position-relative">
                            <label for="username">Last Name</label>
                            <input
                              type="text"
                              name="LastName"
                              id="lastname"
                              className="form-control"
                              placeholder="User name "
                              value={data.LastName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group position-relative">
                            <label for="username">User Name</label>
                            <input
                              type="text"
                              name="UserName"
                              id="username"
                              className="form-control"
                              placeholder="User name "
                              value={data.UserName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5">
                          <div className="form-group position-relative">
                            <label for="username">Email</label>
                            <input
                              type="email"
                              name="Email"
                              id="email"
                              className="form-control"
                              placeholder="User name "
                              value={data.Email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group position-relative">
                            <label for="password">Password</label>
                            <input
                              type={showPassword ? "text" : "password"}
                              name="Password"
                              id="password"
                              className="passwordType form-control"
                              placeholder="enter new password"
                              onChange={handleChange}
                            />
                            <div className="show_password" onClick={showpass}>
                              <span className="show_icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  aria-hidden="true"
                                  role="img"
                                  width="1em"
                                  height="1em"
                                  preserveAspectRatio="xMidYMid meet"
                                  viewBox="0 0 24 24"
                                >
                                  <g fill="currentColor">
                                    <circle cx="12" cy="12" r="1.5"></circle>
                                    <path d="M15.29 18.12L14 16.78l-.07-.07l-1.27-1.27a4.07 4.07 0 0 1-.61.06A3.5 3.5 0 0 1 8.5 12a4.07 4.07 0 0 1 .06-.61l-2-2L5 7.87a15.89 15.89 0 0 0-2.87 3.63a1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25a9.48 9.48 0 0 0 3.23-.67zM8.59 5.76l2.8 2.8A4.07 4.07 0 0 1 12 8.5a3.5 3.5 0 0 1 3.5 3.5a4.07 4.07 0 0 1-.06.61l2.68 2.68l.84.84a15.89 15.89 0 0 0 2.91-3.63a1 1 0 0 0 0-1c-.64-1.11-4.16-6.68-10.14-6.5a9.48 9.48 0 0 0-3.23.67zm12.12 13.53L19.41 18l-2-2l-9.52-9.53L6.42 5L4.71 3.29a1 1 0 0 0-1.42 1.42L5.53 7l1.75 1.7l7.31 7.3l.07.07L16 17.41l.59.59l2.7 2.71a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42z"></path>
                                  </g>
                                </svg>
                              </span>

                              <span className="hide_icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  aria-hidden="true"
                                  role="img"
                                  width="1em"
                                  height="1em"
                                  preserveAspectRatio="xMidYMid meet"
                                  viewBox="0 0 24 24"
                                >
                                  <g fill="currentColor">
                                    <circle cx="12" cy="12" r="1.5"></circle>
                                    <path d="M21.87 11.5c-.64-1.11-4.16-6.68-10.14-6.5c-5.53.14-8.73 5-9.6 6.5a1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25c5.53-.14 8.74-5 9.6-6.5a1 1 0 0 0 0-1zm-9.87 4a3.5 3.5 0 1 1 3.5-3.5a3.5 3.5 0 0 1-3.5 3.5z"></path>
                                  </g>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        id="updateUserBtn"
                        className="btn btn-block button-primary"
                        disabled={loading ? true : false}
                      >
                        {loading ? "Loading..." : "Update"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminEditSingleCPA;
