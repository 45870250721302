import React from "react";
import UserAvatar from "../../images/user_avatar.jpg";
import AdminAvatar from "../../images/admin.png";
import {
  getFirstName,
  getLastName,
  getUserType,
  removeUserSession,
  removeUserName,
} from "../../utils/Common";
import { Link, withRouter, useLocation } from "react-router-dom";

const DashBoardLeft = (props) => {
  const location = useLocation();
  const firstname = getFirstName();
  const lastname = getLastName();
  const usertype = getUserType();

  const logoutApi = async () => {
    await fetch("https://vetacpav2.azurewebsites.net/api/user/logout", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success) {
          removeUserName();
          removeUserSession();
          props.history.push("/");
        } else {
          props.history.push("/");
        }
      });
  };
  const handleLogout = () => {
    logoutApi();
  };

  if (usertype === "Customer") {
    return (
      <div className="col-md-3 navbar-left d-lg-flex flex-column">
        <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
          <div className="side-bar-header text-center">
            <button type="button" className="btn-close-menu">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
                <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z" />
              </svg>
            </button>
            <div className="user-avatar">
              <div className="d-flex flex-column align-items-center">
                <img
                  className="img-avatar mb-1"
                  src={UserAvatar}
                  alt="user_avatar"
                  width="70"
                />
                <span id="user_name">{`${firstname} ${lastname}`}</span>
              </div>
            </div>
          </div>
          <div className="navbar-collapse" id="navbarVerticalCollapse">
            <div className="navbar-vertical-content scrollbar">
              <ul
                className="navbar-nav flex-column mb-3"
                id="navbarVerticalNav"
              >
                <li className="nav-item">
                  <p className="menu-text">Menu</p>
                  <div className="d-block">
                    <Link
                      to="/home"
                      className={
                        location.pathname === "/home"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                          >
                            <path
                              id="Icon_material-dashboard"
                              data-name="Icon material-dashboard"
                              d="M4.5,17.278H14.722V4.5H4.5ZM4.5,27.5H14.722V19.833H4.5Zm12.778,0H27.5V14.722H17.278Zm0-23v7.667H27.5V4.5Z"
                              transform="translate(-4.5 -4.5)"
                              fill="#a8abb7"
                            />
                          </svg>
                        </span>
                        <span className="nav-link-text ">Dashboard</span>
                      </div>
                    </Link>
                    <Link
                      to="/uploadfiles"
                      className={
                        location.pathname.match("uploadfiles")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                          >
                            <path
                              id="Icon_awesome-file-import"
                              data-name="Icon awesome-file-import"
                              d="M.719,12.938A.721.721,0,0,0,0,13.656v1.438a.721.721,0,0,0,.719.719H5.75V12.938ZM22.686,4.717l-4.4-4.4A1.077,1.077,0,0,0,17.524,0H17.25V5.75H23V5.476A1.075,1.075,0,0,0,22.686,4.717ZM15.813,6.109V0H6.828A1.076,1.076,0,0,0,5.75,1.078V12.938H11.5V10.009A.721.721,0,0,1,12.731,9.5l4.295,4.335a.765.765,0,0,1,0,1.078l-4.3,4.33a.721.721,0,0,1-1.231-.508V15.813H5.75v6.109A1.076,1.076,0,0,0,6.828,23H21.922A1.076,1.076,0,0,0,23,21.922V7.188H16.891A1.081,1.081,0,0,1,15.813,6.109Z"
                              fill="#a8abb7"
                            />
                          </svg>
                        </span>
                        <span className="nav-link-text ">Upload files</span>
                      </div>
                    </Link>
                    <Link
                      to="/viewfiles"
                      className={
                        location.pathname === "/viewfiles"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                          >
                            <path
                              id="Icon_metro-files-empty"
                              data-name="Icon metro-files-empty"
                              d="M26.416,9.948a21.464,21.464,0,0,0-2.238-2.239,22.67,22.67,0,0,0-2.559-1.958A4.415,4.415,0,0,0,19.284,4.8H9.838A1.94,1.94,0,0,0,7.784,6.6V23.131a1.94,1.94,0,0,0,2.054,1.8H25.445a1.94,1.94,0,0,0,2.054-1.8V11.991c0-.322-.112-.884-1.083-2.042Zm-3.4-1.223a20.743,20.743,0,0,1,1.863,1.828H20.927V7.1a21.724,21.724,0,0,1,2.089,1.63Zm2.84,14.406a.393.393,0,0,1-.411.359H9.838a.393.393,0,0,1-.411-.359V6.6a.393.393,0,0,1,.411-.359h9.446v5.031a.775.775,0,0,0,.821.719h5.75ZM18.333,2.876A4.415,4.415,0,0,0,16,1.928H6.552A1.94,1.94,0,0,0,4.5,3.725V20.256a1.879,1.879,0,0,0,1.643,1.761V3.725a.393.393,0,0,1,.411-.359h12.5c-.248-.178-.488-.343-.718-.49Z"
                              transform="translate(-4.499 -1.928)"
                              fill="#a8abb7"
                            />
                          </svg>
                        </span>
                        <span className="nav-link-text ">View files</span>
                      </div>
                    </Link>
                    <Link
                      to="/foldermanagement"
                      className={
                        location.pathname.match("folder")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="21"
                            viewBox="0 0 25 21"
                          >
                            <path
                              id="Icon_awesome-folder-open"
                              data-name="Icon awesome-folder-open"
                              d="M24.857,16.974l-3.143,6.79a2.734,2.734,0,0,1-2.4,1.736H1.954c-.8,0-1.3-1.1-.9-1.974L4.2,16.736A2.734,2.734,0,0,1,6.6,15h17.36c.8,0,1.3,1.1.9,1.974ZM6.6,13.25H20.833V10.625A2.4,2.4,0,0,0,18.75,8H11.806L9.028,4.5H2.083A2.4,2.4,0,0,0,0,7.125V22.331l3-6.476A4.114,4.114,0,0,1,6.6,13.25Z"
                              transform="translate(0 -4.5)"
                              fill="#a8abb7"
                            />
                          </svg>
                        </span>
                        <span className="nav-link-text ">Folders</span>
                      </div>
                    </Link>
                    <Link
                      to="/editprofile"
                      className={
                        location.pathname === "/editprofile"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                          >
                            <path
                              id="Icon_metro-profile"
                              data-name="Icon metro-profile"
                              d="M25.856,11H24.248V7.78l-3.32-.068L20.945,11H10.967l.068-3.286L7.784,7.78v3.286L6.142,11c-.907,0-1.643.981-1.643,2.19V28.522c0,1.209.736,2.19,1.643,2.19H25.856c.907,0,1.643-.981,1.643-2.19V13.188C27.5,11.979,26.763,11,25.856,11Zm-4.107-2.19h1.643v4.381H21.749ZM11.07,16.268c.992,0,1.8,1.41,1.8,3.149s-.805,3.149-1.8,3.149-1.8-1.41-1.8-3.149.805-3.149,1.8-3.149ZM8.606,8.807h1.643v4.381H8.606ZM7.511,26.274s.195-2.083.637-2.476a5.133,5.133,0,0,1,1.717-.655s.826,1.177,1.178,1.177,1.177-1.177,1.177-1.177a5.117,5.117,0,0,1,1.718.655c.52.462.65,2.476.65,2.476H7.511Zm16.7-1.038H16.82v-1.1h7.393Zm0-2.19H16.82v-1.1h7.393Zm0-2.19H16.82v-1.1h7.393Zm0-2.19H16.82v-1.1h7.393Z"
                              transform="translate(-4.499 -7.712)"
                              fill="#a8abb7"
                            />
                          </svg>
                        </span>
                        <span className="nav-link-text ">Edit profile</span>
                      </div>
                    </Link>
                  </div>
                  <Link
                    className="nav-link"
                    onClick={handleLogout}
                    to=""
                    role="button"
                  >
                    <div className="d-flex align-items-center">
                      <span className="nav-link-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          viewBox="0 0 23 23"
                        >
                          <path
                            id="Icon_open-account-logout"
                            data-name="Icon open-account-logout"
                            d="M8.625,0V3.286h11.5V19.714H8.625V23H23V0ZM5.75,6.571,0,11.5l5.75,4.929V13.143h11.5V9.857H5.75Z"
                            fill="#a8abb7"
                          />
                        </svg>
                      </span>
                      <span className="nav-link-text" onClick={handleLogout}>
                        Logout
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  } else if (usertype === "Admin") {
    return (
      <div className="col-md-3 navbar-left d-lg-flex flex-column">
        <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
          <div className="side-bar-header text-center">
            <button type="button" className="btn-close-menu">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
                <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z" />
              </svg>
            </button>
            <div className="user-avatar">
              <div className="d-flex flex-column align-items-center">
                <img
                  className="img-avatar mb-1"
                  src={AdminAvatar}
                  alt="user_avatar"
                  width="70"
                />
                <span id="user_name">{`${firstname} ${lastname}`}</span>
              </div>
            </div>
          </div>
          <div className="navbar-collapse" id="navbarVerticalCollapse">
            <div className="navbar-vertical-content scrollbar">
              <ul
                className="navbar-nav flex-column mb-3"
                id="navbarVerticalNav"
              >
                <li className="nav-item">
                  <p className="menu-text">Menu</p>
                  <div className="d-block">
                    <Link
                      to="/home"
                      className={
                        location.pathname === "/home"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                          >
                            <path
                              id="Icon_material-dashboard"
                              data-name="Icon material-dashboard"
                              d="M4.5,17.278H14.722V4.5H4.5ZM4.5,27.5H14.722V19.833H4.5Zm12.778,0H27.5V14.722H17.278Zm0-23v7.667H27.5V4.5Z"
                              transform="translate(-4.5 -4.5)"
                              fill="#a8abb7"
                            />
                          </svg>
                        </span>
                        <span className="nav-link-text ">Dashboard</span>
                      </div>
                    </Link>
                    <Link
                      to="/manageclients"
                      className={
                        location.pathname.match("client")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      role="button"
                    >
                      <div class="d-flex align-items-center">
                        <span class="nav-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="27.127"
                            height="18.989"
                            viewBox="0 0 27.127 18.989"
                          >
                            <path
                              id="Icon_awesome-user-friends"
                              data-name="Icon awesome-user-friends"
                              d="M8.138,11.744A4.747,4.747,0,1,0,3.391,7,4.745,4.745,0,0,0,8.138,11.744ZM11.393,13.1h-.352a6.554,6.554,0,0,1-5.807,0H4.883A4.884,4.884,0,0,0,0,17.984V19.2a2.035,2.035,0,0,0,2.035,2.035H14.242A2.035,2.035,0,0,0,16.276,19.2V17.984A4.884,4.884,0,0,0,11.393,13.1Zm8.952-1.356a4.069,4.069,0,1,0-4.069-4.069A4.07,4.07,0,0,0,20.345,11.744ZM22.38,13.1h-.161a5.345,5.345,0,0,1-3.747,0h-.161a4.708,4.708,0,0,0-2.361.653,6.2,6.2,0,0,1,1.683,4.23v1.628c0,.093-.021.182-.025.271h7.485a2.035,2.035,0,0,0,2.035-2.035A4.745,4.745,0,0,0,22.38,13.1Z"
                              transform="translate(0 -2.25)"
                              fill="#a8abb7"
                            />
                          </svg>
                        </span>
                        <span class="nav-link-text ps-1">Manage clients</span>
                      </div>
                    </Link>
                    <Link
                      to="/managecpa"
                      className={
                        location.pathname.match("managecpa")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      href="manageClients.html"
                      role="button"
                    >
                      <div class="d-flex align-items-center">
                        <span class="nav-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="27.127"
                            height="18.989"
                            viewBox="0 0 27.127 18.989"
                          >
                            <path
                              id="Icon_awesome-user-friends"
                              data-name="Icon awesome-user-friends"
                              d="M8.138,11.744A4.747,4.747,0,1,0,3.391,7,4.745,4.745,0,0,0,8.138,11.744ZM11.393,13.1h-.352a6.554,6.554,0,0,1-5.807,0H4.883A4.884,4.884,0,0,0,0,17.984V19.2a2.035,2.035,0,0,0,2.035,2.035H14.242A2.035,2.035,0,0,0,16.276,19.2V17.984A4.884,4.884,0,0,0,11.393,13.1Zm8.952-1.356a4.069,4.069,0,1,0-4.069-4.069A4.07,4.07,0,0,0,20.345,11.744ZM22.38,13.1h-.161a5.345,5.345,0,0,1-3.747,0h-.161a4.708,4.708,0,0,0-2.361.653,6.2,6.2,0,0,1,1.683,4.23v1.628c0,.093-.021.182-.025.271h7.485a2.035,2.035,0,0,0,2.035-2.035A4.745,4.745,0,0,0,22.38,13.1Z"
                              transform="translate(0 -2.25)"
                              fill="#a8abb7"
                            />
                          </svg>
                        </span>
                        <span class="nav-link-text ps-1">Manage CPA</span>
                      </div>
                    </Link>
                    <Link
                      to="/adminviewfiles"
                      className={
                        location.pathname === "/adminviewfiles"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                          >
                            <path
                              id="Icon_metro-files-empty"
                              data-name="Icon metro-files-empty"
                              d="M26.416,9.948a21.464,21.464,0,0,0-2.238-2.239,22.67,22.67,0,0,0-2.559-1.958A4.415,4.415,0,0,0,19.284,4.8H9.838A1.94,1.94,0,0,0,7.784,6.6V23.131a1.94,1.94,0,0,0,2.054,1.8H25.445a1.94,1.94,0,0,0,2.054-1.8V11.991c0-.322-.112-.884-1.083-2.042Zm-3.4-1.223a20.743,20.743,0,0,1,1.863,1.828H20.927V7.1a21.724,21.724,0,0,1,2.089,1.63Zm2.84,14.406a.393.393,0,0,1-.411.359H9.838a.393.393,0,0,1-.411-.359V6.6a.393.393,0,0,1,.411-.359h9.446v5.031a.775.775,0,0,0,.821.719h5.75ZM18.333,2.876A4.415,4.415,0,0,0,16,1.928H6.552A1.94,1.94,0,0,0,4.5,3.725V20.256a1.879,1.879,0,0,0,1.643,1.761V3.725a.393.393,0,0,1,.411-.359h12.5c-.248-.178-.488-.343-.718-.49Z"
                              transform="translate(-4.499 -1.928)"
                              fill="#a8abb7"
                            />
                          </svg>
                        </span>
                        <span className="nav-link-text ">View files</span>
                      </div>
                    </Link>
                    <Link
                      to="/settings"
                      className={
                        location.pathname === "/settings"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      role="button"
                    >
                      <div class="d-flex align-items-center">
                        <span class="nav-link-icon">
                          <svg
                            id="Icon_feather-settings"
                            data-name="Icon feather-settings"
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                          >
                            <path
                              id="Path_110"
                              data-name="Path 110"
                              d="M19.773,16.636A3.136,3.136,0,1,1,16.636,13.5,3.136,3.136,0,0,1,19.773,16.636Z"
                              transform="translate(-3.636 -3.636)"
                              fill="none"
                              stroke="#a8abb7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="3"
                            />
                            <path
                              id="Path_111"
                              data-name="Path 111"
                              d="M20.736,16.136a1.725,1.725,0,0,0,.345,1.9l.063.063a2.092,2.092,0,1,1-2.959,2.959L18.123,21a1.739,1.739,0,0,0-2.948,1.234v.178a2.091,2.091,0,0,1-4.182,0v-.094a1.725,1.725,0,0,0-1.129-1.579,1.725,1.725,0,0,0-1.9.345l-.063.063A2.092,2.092,0,1,1,4.94,18.185L5,18.123a1.739,1.739,0,0,0-1.234-2.948H3.591a2.091,2.091,0,0,1,0-4.182h.094A1.725,1.725,0,0,0,5.264,9.864a1.725,1.725,0,0,0-.345-1.9L4.856,7.9A2.092,2.092,0,1,1,7.815,4.94L7.877,5a1.725,1.725,0,0,0,1.9.345h.084a1.725,1.725,0,0,0,1.045-1.579V3.591a2.091,2.091,0,0,1,4.182,0v.094a1.739,1.739,0,0,0,2.948,1.234l.063-.063A2.092,2.092,0,1,1,21.06,7.815L21,7.877a1.725,1.725,0,0,0-.345,1.9v.084a1.725,1.725,0,0,0,1.579,1.045h.178a2.091,2.091,0,0,1,0,4.182h-.094a1.725,1.725,0,0,0-1.579,1.045Z"
                              fill="none"
                              stroke="#a8abb7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="3"
                            />
                          </svg>
                        </span>
                        <span class="nav-link-text ps-1">Settings</span>
                      </div>
                    </Link>
                    <Link
                      to="/editadminprofile"
                      className={
                        location.pathname === "/editadminprofile"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                          >
                            <path
                              id="Icon_metro-profile"
                              data-name="Icon metro-profile"
                              d="M25.856,11H24.248V7.78l-3.32-.068L20.945,11H10.967l.068-3.286L7.784,7.78v3.286L6.142,11c-.907,0-1.643.981-1.643,2.19V28.522c0,1.209.736,2.19,1.643,2.19H25.856c.907,0,1.643-.981,1.643-2.19V13.188C27.5,11.979,26.763,11,25.856,11Zm-4.107-2.19h1.643v4.381H21.749ZM11.07,16.268c.992,0,1.8,1.41,1.8,3.149s-.805,3.149-1.8,3.149-1.8-1.41-1.8-3.149.805-3.149,1.8-3.149ZM8.606,8.807h1.643v4.381H8.606ZM7.511,26.274s.195-2.083.637-2.476a5.133,5.133,0,0,1,1.717-.655s.826,1.177,1.178,1.177,1.177-1.177,1.177-1.177a5.117,5.117,0,0,1,1.718.655c.52.462.65,2.476.65,2.476H7.511Zm16.7-1.038H16.82v-1.1h7.393Zm0-2.19H16.82v-1.1h7.393Zm0-2.19H16.82v-1.1h7.393Zm0-2.19H16.82v-1.1h7.393Z"
                              transform="translate(-4.499 -7.712)"
                              fill="#a8abb7"
                            />
                          </svg>
                        </span>
                        <span className="nav-link-text ">Edit profile</span>
                      </div>
                    </Link>
                  </div>
                  <Link
                    className="nav-link"
                    onClick={handleLogout}
                    role="button"
                    to=""
                  >
                    <div className="d-flex align-items-center">
                      <span className="nav-link-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          viewBox="0 0 23 23"
                        >
                          <path
                            id="Icon_open-account-logout"
                            data-name="Icon open-account-logout"
                            d="M8.625,0V3.286h11.5V19.714H8.625V23H23V0ZM5.75,6.571,0,11.5l5.75,4.929V13.143h11.5V9.857H5.75Z"
                            fill="#a8abb7"
                          />
                        </svg>
                      </span>
                      <span className="nav-link-text" onClick={handleLogout}>
                        Logout
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  } else if (usertype === "CPA") {
    return (
      <div className="col-md-3 navbar-left d-lg-flex flex-column">
        <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
          <div className="side-bar-header text-center">
            <button type="button" className="btn-close-menu">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
                <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z" />
              </svg>
            </button>
            <div className="user-avatar">
              <div className="d-flex flex-column align-items-center">
                <img
                  className="img-avatar mb-1"
                  src={AdminAvatar}
                  alt="user_avatar"
                  width="70"
                />
                <span id="user_name">{`${firstname} ${lastname}`}</span>
              </div>
            </div>
          </div>
          <div className="navbar-collapse" id="navbarVerticalCollapse">
            <div className="navbar-vertical-content scrollbar">
              <ul
                className="navbar-nav flex-column mb-3"
                id="navbarVerticalNav"
              >
                <li className="nav-item">
                  <p className="menu-text">Menu</p>
                  <div className="d-block">
                    <Link
                      to="/home"
                      className={
                        location.pathname === "/home"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                          >
                            <path
                              id="Icon_material-dashboard"
                              data-name="Icon material-dashboard"
                              d="M4.5,17.278H14.722V4.5H4.5ZM4.5,27.5H14.722V19.833H4.5Zm12.778,0H27.5V14.722H17.278Zm0-23v7.667H27.5V4.5Z"
                              transform="translate(-4.5 -4.5)"
                              fill="#a8abb7"
                            />
                          </svg>
                        </span>
                        <span className="nav-link-text ">Dashboard</span>
                      </div>
                    </Link>
                    <Link
                      to="/cpa/viewclients"
                      className={
                        location.pathname.match("clients")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      role="button"
                    >
                      <div class="d-flex align-items-center">
                        <span class="nav-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="27.127"
                            height="18.989"
                            viewBox="0 0 27.127 18.989"
                          >
                            <path
                              id="Icon_awesome-user-friends"
                              data-name="Icon awesome-user-friends"
                              d="M8.138,11.744A4.747,4.747,0,1,0,3.391,7,4.745,4.745,0,0,0,8.138,11.744ZM11.393,13.1h-.352a6.554,6.554,0,0,1-5.807,0H4.883A4.884,4.884,0,0,0,0,17.984V19.2a2.035,2.035,0,0,0,2.035,2.035H14.242A2.035,2.035,0,0,0,16.276,19.2V17.984A4.884,4.884,0,0,0,11.393,13.1Zm8.952-1.356a4.069,4.069,0,1,0-4.069-4.069A4.07,4.07,0,0,0,20.345,11.744ZM22.38,13.1h-.161a5.345,5.345,0,0,1-3.747,0h-.161a4.708,4.708,0,0,0-2.361.653,6.2,6.2,0,0,1,1.683,4.23v1.628c0,.093-.021.182-.025.271h7.485a2.035,2.035,0,0,0,2.035-2.035A4.745,4.745,0,0,0,22.38,13.1Z"
                              transform="translate(0 -2.25)"
                              fill="#a8abb7"
                            />
                          </svg>
                        </span>
                        <span class="nav-link-text ps-1">Manage clients</span>
                      </div>
                    </Link>
                    <Link
                      to="/adminviewfiles"
                      className={
                        location.pathname.match("files")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                          >
                            <path
                              id="Icon_metro-files-empty"
                              data-name="Icon metro-files-empty"
                              d="M26.416,9.948a21.464,21.464,0,0,0-2.238-2.239,22.67,22.67,0,0,0-2.559-1.958A4.415,4.415,0,0,0,19.284,4.8H9.838A1.94,1.94,0,0,0,7.784,6.6V23.131a1.94,1.94,0,0,0,2.054,1.8H25.445a1.94,1.94,0,0,0,2.054-1.8V11.991c0-.322-.112-.884-1.083-2.042Zm-3.4-1.223a20.743,20.743,0,0,1,1.863,1.828H20.927V7.1a21.724,21.724,0,0,1,2.089,1.63Zm2.84,14.406a.393.393,0,0,1-.411.359H9.838a.393.393,0,0,1-.411-.359V6.6a.393.393,0,0,1,.411-.359h9.446v5.031a.775.775,0,0,0,.821.719h5.75ZM18.333,2.876A4.415,4.415,0,0,0,16,1.928H6.552A1.94,1.94,0,0,0,4.5,3.725V20.256a1.879,1.879,0,0,0,1.643,1.761V3.725a.393.393,0,0,1,.411-.359h12.5c-.248-.178-.488-.343-.718-.49Z"
                              transform="translate(-4.499 -1.928)"
                              fill="#a8abb7"
                            />
                          </svg>
                        </span>
                        <span className="nav-link-text ">View files</span>
                      </div>
                    </Link>
                    <Link
                      to="/editcpaprofile"
                      className={
                        location.pathname === "/editcpaprofile"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                          >
                            <path
                              id="Icon_metro-profile"
                              data-name="Icon metro-profile"
                              d="M25.856,11H24.248V7.78l-3.32-.068L20.945,11H10.967l.068-3.286L7.784,7.78v3.286L6.142,11c-.907,0-1.643.981-1.643,2.19V28.522c0,1.209.736,2.19,1.643,2.19H25.856c.907,0,1.643-.981,1.643-2.19V13.188C27.5,11.979,26.763,11,25.856,11Zm-4.107-2.19h1.643v4.381H21.749ZM11.07,16.268c.992,0,1.8,1.41,1.8,3.149s-.805,3.149-1.8,3.149-1.8-1.41-1.8-3.149.805-3.149,1.8-3.149ZM8.606,8.807h1.643v4.381H8.606ZM7.511,26.274s.195-2.083.637-2.476a5.133,5.133,0,0,1,1.717-.655s.826,1.177,1.178,1.177,1.177-1.177,1.177-1.177a5.117,5.117,0,0,1,1.718.655c.52.462.65,2.476.65,2.476H7.511Zm16.7-1.038H16.82v-1.1h7.393Zm0-2.19H16.82v-1.1h7.393Zm0-2.19H16.82v-1.1h7.393Zm0-2.19H16.82v-1.1h7.393Z"
                              transform="translate(-4.499 -7.712)"
                              fill="#a8abb7"
                            />
                          </svg>
                        </span>
                        <span className="nav-link-text ">Edit profile</span>
                      </div>
                    </Link>
                  </div>
                  <Link
                    className="nav-link"
                    onClick={handleLogout}
                    role="button"
                    to=""
                  >
                    <div className="d-flex align-items-center">
                      <span className="nav-link-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          viewBox="0 0 23 23"
                        >
                          <path
                            id="Icon_open-account-logout"
                            data-name="Icon open-account-logout"
                            d="M8.625,0V3.286h11.5V19.714H8.625V23H23V0ZM5.75,6.571,0,11.5l5.75,4.929V13.143h11.5V9.857H5.75Z"
                            fill="#a8abb7"
                          />
                        </svg>
                      </span>
                      <span className="nav-link-text" onClick={handleLogout}>
                        Logout
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
};

export default withRouter(DashBoardLeft);
