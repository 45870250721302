import React from "react";
import "./App.css";
import { HashRouter } from "react-router-dom";
import MainRouter from "./components/MainRouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <HashRouter>
      <ToastContainer position="top-center" />
      <MainRouter />
    </HashRouter>
  );
}

export default App;
