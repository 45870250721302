import React, { useState, useEffect } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandLogo from "../../images/brandlogo.png";
import { getUserID, getToken } from "../../utils/Common";
import { toast } from "react-toastify";
import back from "../../images/back.png";
import { useHistory } from "react-router-dom";

const CustomerUploadFile = (props) => {
  let history = useHistory();
  const token = getToken();
  const id = getUserID();
  const [folderDetails, setFolderDetails] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [input, setInput] = useState([]);

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const getFolder = async () => {
      const details = await fetch(
        `https://vetacpav2.azurewebsites.net/api/folder/`,
        {
          headers: {
            Accept: "application/json",
            "auth-token": token,
            "user-id": id,
            "content-type": "application/json",
          },
        }
      );
      const data = await details.json();
      setFolderDetails(data);
    };

    const getAdmins = async () => {
      const details = await fetch(
        `https://vetacpav2.azurewebsites.net/api/user/adminsandcpa`,
        {
          headers: {
            Accept: "application/json",
            "auth-token": token,
            "user-id": id,
            "content-type": "application/json",
          },
        }
      );
      const data = await details.json();
      setAdmins(data);
    };
    getFolder();
    getAdmins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonHandler = (e) => {
    e.preventDefault();
    if (!input.CPAId || !input.FolderId) {
      toast.error("Please Select Both folder and CPA");
    } else {
      props.history.push(
        `/uploadfiles/clientuploadfile/${input.FolderId}/${input.CPAId}`
      );
    }
  };

  return (
    <div id="main-content">
      <main className="dashBoard" id="main-wrapper">
        <div className="opacity_bg"></div>
        <div className="container-fluid">
          <div className="content">
            <div className="row">
              <DashBoardLeft />
              <div className="col-md-12 col-lg-9">
                <div className="card card-border-radius">
                  <div className="card-header position-relative">
                    <div className="toggle-icon-wrapper">
                      <button
                        className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                        id="navbar_humburger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="navbar_humburger"
                        data-bs-original-title="Toggle Navigation"
                        aria-label="Toggle Navigation"
                      >
                        <span className="navbar-toggle-icon">
                          <span className="toggle-line"></span>
                        </span>
                      </button>
                    </div>
                    <img
                      src={brandLogo}
                      width="585"
                      height="113"
                      alt="Veta & Association Logo"
                      className="logo"
                    />
                  </div>

                  <div className="card-body">
                    <div className="row justify-content-between">
                      <div className="col-6"></div>
                      <div className="col-6 d-flex justify-content-end">
                        <img
                          src={back}
                          alt="back"
                          style={{ width: "10%", cursor: "pointer" }}
                          onClick={history.goBack}
                        />
                      </div>
                    </div>
                    <h2>
                      Upload Files &nbsp;
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="33.098"
                          height="33.093"
                          viewBox="0 0 33.098 33.093"
                        >
                          <path
                            id="Icon_awesome-upload"
                            data-name="Icon awesome-upload"
                            d="M19.135,24.824H13.963a1.548,1.548,0,0,1-1.551-1.551V12.412H6.742a1.29,1.29,0,0,1-.911-2.2L15.663.369a1.252,1.252,0,0,1,1.765,0l9.839,9.839a1.29,1.29,0,0,1-.911,2.2H20.686v10.86A1.548,1.548,0,0,1,19.135,24.824ZM33.1,24.307v7.24A1.548,1.548,0,0,1,31.546,33.1H1.551A1.548,1.548,0,0,1,0,31.547v-7.24a1.548,1.548,0,0,1,1.551-1.551h8.792v.517a3.622,3.622,0,0,0,3.62,3.62h5.172a3.622,3.622,0,0,0,3.62-3.62v-.517h8.792A1.548,1.548,0,0,1,33.1,24.307ZM25.082,30a1.293,1.293,0,1,0-1.293,1.293A1.3,1.3,0,0,0,25.082,30Zm4.137,0a1.293,1.293,0,1,0-1.293,1.293A1.3,1.3,0,0,0,29.219,30Z"
                            transform="translate(0 -0.005)"
                            fill="#b30000"
                          />
                        </svg>
                      </span>
                    </h2>
                    <p>Select the folder and CPA to upload your files</p>
                    <p>
                      The file name should only include “_” and “.” as special
                      characters
                    </p>
                    <form action="" method="post" id="upload_files_form">
                      <div className="form-group position-relative">
                        <label for="folderSelect">Select folder</label>
                        <select
                          className="form-control"
                          id="folderSelect"
                          aria-label="Default select example"
                          onChange={handleChange}
                          name="FolderId"
                          value={input.FolderId}
                          required="true"
                        >
                          <option disabled selected value="">
                            {" "}
                            -- select an option --{" "}
                          </option>
                          {folderDetails.map((folder) => {
                            return (
                              <option value={folder._id}>
                                {folder.FolderName}
                              </option>
                            );
                          })}
                        </select>
                        <span className="arrow-down" aria-label="arrow"></span>
                      </div>
                      <div className="form-group position-relative">
                        <label for="selectCPA">Select the CPA</label>
                        <select
                          className="form-control"
                          id="selectCPA"
                          aria-label="Default select example"
                          onChange={handleChange}
                          name="CPAId"
                          value={input.CPAId}
                          required="true"
                        >
                          <option disabled selected value="">
                            {" "}
                            -- select an option --{" "}
                          </option>
                          {admins.map((singleadmin) => {
                            return (
                              <option value={singleadmin._id}>
                                {singleadmin.FirstName} {singleadmin.LastName}
                              </option>
                            );
                          })}
                        </select>
                        <span className="arrow-down" aria-label="arrow"></span>
                      </div>
                      <button
                        type="submit"
                        id="updateBtn"
                        className="btn btn-block button-primary"
                        onClick={buttonHandler}
                      >
                        Update
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CustomerUploadFile;
