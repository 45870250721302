import React from "react";
import noFile from "../../images/nofile.png";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandlogo from "../../images/brandlogo.png";
import back from "../../images/back.png";
import { useHistory } from "react-router-dom";

const NoFileFound = () => {
  let history = useHistory();
  return (
    <div id="main-content">
      <main className="dashBoard" id="main-wrapper">
        <div className="opacity_bg"></div>
        <div className="container-fluid">
          <div className="content">
            <div className="row">
              <DashBoardLeft />
              <div className="col-md-12 col-lg-9">
                <div className="card card-border-radius">
                  <div className="card-header position-relative">
                    {/*button to toggle sidebar for smaller width*/}
                    <div className="toggle-icon-wrapper">
                      <button
                        className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                        id="navbar_humburger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="navbar_humburger"
                        data-bs-original-title="Toggle Navigation"
                        aria-label="Toggle Navigation"
                      >
                        <span className="navbar-toggle-icon">
                          <span className="toggle-line"></span>
                        </span>
                      </button>
                    </div>

                    {/*Brand Logo Images*/}
                    <img
                      src={brandlogo}
                      width="585"
                      height="113"
                      alt="Veta & Association Logo"
                      className="logo"
                    />
                  </div>

                  <div className="card-body mx-auto">
                    <div className="row">
                      <div className="col-6"></div>
                      <div className="col-6 d-flex justify-content-end">
                        <img
                          src={back}
                          alt="back"
                          style={{ width: "10%", cursor: "pointer" }}
                          onClick={history.goBack}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mx-auto">
                        <h1 className="pb-2 b">No files were found..!!</h1>
                        <br />
                        <img
                          src={noFile}
                          style={{ width: "70%" }}
                          alt="no-file"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NoFileFound;
