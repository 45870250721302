import React, { useState } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandlogo from "../../images/brandlogo.png";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { getToken } from "../../utils/Common";
import back from "../../images/back.png";

const AdminAddCPA = () => {
  let history = useHistory();
  const token = getToken();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const addCPA = async () => {
    await fetch("https://vetacpav2.azurewebsites.net/api/user/createcpa", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "auth-token": token,
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message) {
          setLoading(false);
          toast.error(responseJson.message);
        } else {
          setLoading(false);
          toast.success(responseJson.success);
          history.goBack();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const showpass = (e) => {
    setShowPassword(!showPassword);
  };

  const showCnfrmPass = (e) => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const formSubmitter = (e) => {
    e.preventDefault();
    setLoading(true);
    addCPA();
  };

  return (
    <div id="main-content">
      <main className="dashBoard" id="main-wrapper">
        <div className="opacity_bg"></div>
        <div className="container-fluid">
          <div className="content">
            <div className="row">
              <DashBoardLeft />
              <div className="col-md-12 col-lg-9">
                <div className="card card-border-radius">
                  <div className="card-header position-relative">
                    <div className="toggle-icon-wrapper">
                      <button
                        className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                        id="navbar_humburger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="navbar_humburger"
                        data-bs-original-title="Toggle Navigation"
                        aria-label="Toggle Navigation"
                      >
                        <span className="navbar-toggle-icon">
                          <span className="toggle-line"></span>
                        </span>
                      </button>
                    </div>
                    <img
                      src={brandlogo}
                      width="585"
                      height="113"
                      alt="Veta & Association Logo"
                      className="logo"
                    />
                  </div>

                  <div className="card-body">
                    <div className="row justify-content-between">
                      <div className="col-6"></div>
                      <div className="col-6 d-flex justify-content-end">
                        <img
                          src={back}
                          alt="back"
                          style={{ width: "10%", cursor: "pointer" }}
                          onClick={history.goBack}
                        />
                      </div>
                    </div>
                    <h4 classNameName="text-center">
                      Add CPA
                      {/*<img src={addClient} /> */}
                    </h4>
                    <br />
                    <form onSubmit={formSubmitter} id="edit_profile_form">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="form-group position-relative">
                            <label for="firstname">First Name</label>
                            <input
                              type="text"
                              name="FirstName"
                              id="firstname"
                              className="form-control"
                              placeholder="First Name"
                              value={data.FirstName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5">
                          <div className="form-group position-relative">
                            <label for="lastname">Last Name</label>
                            <input
                              type="text"
                              name="LastName"
                              id="lastname"
                              className="form-control"
                              placeholder="Last Name"
                              value={data.LastName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group position-relative">
                            <label for="username">User Name</label>
                            <input
                              type="text"
                              name="UserName"
                              id="username"
                              className="form-control"
                              placeholder="User name "
                              value={data.UserName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5">
                          <div className="form-group position-relative">
                            <label for="email">Email</label>
                            <input
                              type="email"
                              name="Email"
                              id="email"
                              className="form-control"
                              placeholder="Email"
                              value={data.Email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group position-relative">
                            <label for="password">Password</label>
                            <input
                              type={showPassword ? "text" : "password"}
                              name="Password"
                              id="password"
                              className="passwordType form-control"
                              placeholder="enter your password"
                              onChange={handleChange}
                            />
                            <div className="show_password" onClick={showpass}>
                              <span className="show_icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  aria-hidden="true"
                                  role="img"
                                  width="1em"
                                  height="1em"
                                  preserveAspectRatio="xMidYMid meet"
                                  viewBox="0 0 24 24"
                                >
                                  <g fill="currentColor">
                                    <circle cx="12" cy="12" r="1.5"></circle>
                                    <path d="M15.29 18.12L14 16.78l-.07-.07l-1.27-1.27a4.07 4.07 0 0 1-.61.06A3.5 3.5 0 0 1 8.5 12a4.07 4.07 0 0 1 .06-.61l-2-2L5 7.87a15.89 15.89 0 0 0-2.87 3.63a1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25a9.48 9.48 0 0 0 3.23-.67zM8.59 5.76l2.8 2.8A4.07 4.07 0 0 1 12 8.5a3.5 3.5 0 0 1 3.5 3.5a4.07 4.07 0 0 1-.06.61l2.68 2.68l.84.84a15.89 15.89 0 0 0 2.91-3.63a1 1 0 0 0 0-1c-.64-1.11-4.16-6.68-10.14-6.5a9.48 9.48 0 0 0-3.23.67zm12.12 13.53L19.41 18l-2-2l-9.52-9.53L6.42 5L4.71 3.29a1 1 0 0 0-1.42 1.42L5.53 7l1.75 1.7l7.31 7.3l.07.07L16 17.41l.59.59l2.7 2.71a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42z"></path>
                                  </g>
                                </svg>
                              </span>

                              <span className="hide_icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  aria-hidden="true"
                                  role="img"
                                  width="1em"
                                  height="1em"
                                  preserveAspectRatio="xMidYMid meet"
                                  viewBox="0 0 24 24"
                                >
                                  <g fill="currentColor">
                                    <circle cx="12" cy="12" r="1.5"></circle>
                                    <path d="M21.87 11.5c-.64-1.11-4.16-6.68-10.14-6.5c-5.53.14-8.73 5-9.6 6.5a1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25c5.53-.14 8.74-5 9.6-6.5a1 1 0 0 0 0-1zm-9.87 4a3.5 3.5 0 1 1 3.5-3.5a3.5 3.5 0 0 1-3.5 3.5z"></path>
                                  </g>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5">
                          <div className="form-group position-relative">
                            <label for="confirmpassword">
                              Confirm Password
                            </label>
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              name="ConfirmPassword"
                              id="password"
                              className="passwordType form-control"
                              placeholder="enter your password"
                              onChange={handleChange}
                            />
                            <div
                              className="show_password"
                              onClick={showCnfrmPass}
                            >
                              <span className="show_icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  aria-hidden="true"
                                  role="img"
                                  width="1em"
                                  height="1em"
                                  preserveAspectRatio="xMidYMid meet"
                                  viewBox="0 0 24 24"
                                >
                                  <g fill="currentColor">
                                    <circle cx="12" cy="12" r="1.5"></circle>
                                    <path d="M15.29 18.12L14 16.78l-.07-.07l-1.27-1.27a4.07 4.07 0 0 1-.61.06A3.5 3.5 0 0 1 8.5 12a4.07 4.07 0 0 1 .06-.61l-2-2L5 7.87a15.89 15.89 0 0 0-2.87 3.63a1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25a9.48 9.48 0 0 0 3.23-.67zM8.59 5.76l2.8 2.8A4.07 4.07 0 0 1 12 8.5a3.5 3.5 0 0 1 3.5 3.5a4.07 4.07 0 0 1-.06.61l2.68 2.68l.84.84a15.89 15.89 0 0 0 2.91-3.63a1 1 0 0 0 0-1c-.64-1.11-4.16-6.68-10.14-6.5a9.48 9.48 0 0 0-3.23.67zm12.12 13.53L19.41 18l-2-2l-9.52-9.53L6.42 5L4.71 3.29a1 1 0 0 0-1.42 1.42L5.53 7l1.75 1.7l7.31 7.3l.07.07L16 17.41l.59.59l2.7 2.71a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42z"></path>
                                  </g>
                                </svg>
                              </span>

                              <span className="hide_icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  aria-hidden="true"
                                  role="img"
                                  width="1em"
                                  height="1em"
                                  preserveAspectRatio="xMidYMid meet"
                                  viewBox="0 0 24 24"
                                >
                                  <g fill="currentColor">
                                    <circle cx="12" cy="12" r="1.5"></circle>
                                    <path d="M21.87 11.5c-.64-1.11-4.16-6.68-10.14-6.5c-5.53.14-8.73 5-9.6 6.5a1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25c5.53-.14 8.74-5 9.6-6.5a1 1 0 0 0 0-1zm-9.87 4a3.5 3.5 0 1 1 3.5-3.5a3.5 3.5 0 0 1-3.5 3.5z"></path>
                                  </g>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        id="updateUserBtn"
                        className="btn btn-block button-primary"
                        disabled={loading ? true : false}
                      >
                        {loading ? "Loading..." : "Add CPA"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminAddCPA;
