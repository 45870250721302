import React, { useState, useEffect } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandLogo from "../../images/brandlogo.png";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import back from "../../images/back.png";

const AdminCreateFolder = ({ match }, props) => {
  const [fullname, setFullName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getUsers = async () => {
      const details = await fetch(
        `https://vetacpav2.azurewebsites.net/api/customermaster/client/view/${match.params.id}`,
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },
        }
      );
      const data = await details.json();
      setFullName(`${data.FirstName} ${data.LastName}`);
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let history = useHistory();
  const [folderDetails, setFolderDetails] = useState([]);

  const handleChange = (e) => {
    setFolderDetails({ ...folderDetails, [e.target.name]: e.target.value });
  };

  const addFolder = async () => {
    await fetch(
      `https://vetacpav2.azurewebsites.net/api/folder/createfolder/${match.params.id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify(folderDetails),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message) {
          setLoading(false);
          toast.error(responseJson.message);
        } else {
          setLoading(false);
          toast.success(responseJson.success);
          history.goBack();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    addFolder();
  };

  return (
    <div id="main-content">
      <main className="dashBoard" id="main-wrapper">
        <div className="opacity_bg"></div>
        <div className="container-fluid">
          <div className="content">
            <div className="row">
              <DashBoardLeft />
              <div className="col-md-12 col-lg-9">
                <div className="card card-border-radius">
                  <div className="card-header position-relative">
                    <div className="toggle-icon-wrapper">
                      <button
                        className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                        id="navbar_humburger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="navbar_humburger"
                        data-bs-original-title="Toggle Navigation"
                        aria-label="Toggle Navigation"
                      >
                        <span className="navbar-toggle-icon">
                          <span className="toggle-line"></span>
                        </span>
                      </button>
                    </div>

                    <img
                      src={brandLogo}
                      width="585"
                      height="113"
                      alt="Veta & Association Logo"
                      className="logo"
                    />
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-6">
                        <h5
                          style={{ color: "#940603" }}
                        >{`Home >>>  ${fullname}`}</h5>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-6"></div>
                      <div className="col-6 d-flex justify-content-end">
                        <img
                          src={back}
                          alt="back"
                          style={{ width: "10%", cursor: "pointer" }}
                          onClick={history.goBack}
                        />
                      </div>
                    </div>
                    <h2 className="settingTitle">Create Folder</h2>

                    <form onSubmit={submitHandler} id="uploadSettingsForm">
                      <div className="row justify-content-around">
                        <div className="col-sm-12 col-md-6 fileSettings">
                          <div className="form-group position-relative">
                            <label for="foldername">Folder Name :</label>
                            <input
                              type="text"
                              name="FolderName"
                              value={folderDetails.FolderName}
                              id="maxFileSize"
                              className="form-control"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        id="updateUserBtn"
                        className="btn btn-block button-primary"
                        disabled={loading ? true : false}
                      >
                        {loading ? "Loading..." : "Create"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminCreateFolder;
