import React, { useState, useEffect } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandLogo from "../../images/brandlogo.png";
import { Link, useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { getUserID, getToken } from "../../utils/Common";
import nofolder from "../../images/nofolder.png";
import createfolder from "../../images/createfolder.png";
import { format } from "date-fns";
import back from "../../images/back.png";
import viewIcon from "../../images/view.png";
import deleteIcon from "../../images/deleteicon.png";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const CustomerViewFolder = () => {
  let history = useHistory();
  const id = getUserID();
  const token = getToken();
  const [folderDetails, setFolderDetails] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const customerPerPage = 10;
  const pageVisited = pageNumber * customerPerPage;

  const [ascending, setAscending] = useState(true);

  const sortByDate = () => {
    if (ascending === true) {
      const sorted = folderDetails.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setFolderDetails(sorted);
    } else {
      const sorted = folderDetails.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
      setFolderDetails(sorted);
    }
  };

  const sortfiles = () => {
    setAscending(!ascending);
    sortByDate();
  };

  const getFolder = async () => {
    const details = await fetch(
      `https://vetacpav2.azurewebsites.net/api/folder/`,
      {
        headers: {
          Accept: "application/json",
          "auth-token": token,
          "user-id": id,
          "content-type": "application/json",
        },
      }
    );
    const data = await details.json();
    setFolderDetails(data);
  };

  const deleteFolder = (e, value) => {
    let folderid = e.currentTarget.value;
    //delete folder
    const deleteFolderApi = async () => {
      await fetch(
        `https://vetacpav2.azurewebsites.net/api/folder/delete/${folderid}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "user-id": id,
            "content-type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message) {
            setLoading(false);
            toast.error(responseJson.message);
          } else if (responseJson.success) {
            setLoading(false);
            toast.success(responseJson.success);
            getFolder();
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.message);
        });
    };
    Swal.fire({
      title: `\n Are You Sure To Delete The ${value} Folder And It's Contents..?`,
      text: ``,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: ``,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true);
        deleteFolderApi();
      } else {
        setLoading(false);
      }
    });
  };

  const displayFolders = folderDetails
    .slice(pageVisited, pageVisited + customerPerPage)
    .map((folder) => {
      const date = format(new Date(folder.createdAt), "MM-dd-yyyy");
      return (
        <tr>
          <td>{folder.FolderName}</td>
          <td>{date}</td>
          <td>
            <Link to={`/client/folder/files/${folder._id}`}>
              <img
                src={viewIcon}
                alt="view_icon"
                className="ml-3"
                width="21"
                height="18"
              />
            </Link>
          </td>
          <td>
            <button
              type="button"
              className="action_btn deleteData"
              title="Delete"
              onClick={(e) => {
                deleteFolder(e, `${folder.FolderName}`);
              }}
              value={folder._id}
            >
              <img
                src={deleteIcon}
                alt="delete-icon"
                className="ml-3"
                width="14"
                height="18"
              />
            </button>
          </td>
        </tr>
      );
    });

  const pageCount = Math.ceil(folderDetails.length / customerPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    getFolder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (folderDetails.length <= 0) {
    return (
      <div id="main-content">
        <main className="dashBoard" id="main-wrapper">
          <div className="opacity_bg"></div>
          <div className="container-fluid">
            <div className="content">
              <div className="row">
                <DashBoardLeft />
                <div className="col-md-12 col-lg-9">
                  <div className="card card-border-radius">
                    <div className="card-header position-relative">
                      <div className="toggle-icon-wrapper">
                        <button
                          className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                          id="navbar_humburger"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          title="navbar_humburger"
                          data-bs-original-title="Toggle Navigation"
                          aria-label="Toggle Navigation"
                        >
                          <span className="navbar-toggle-icon">
                            <span className="toggle-line"></span>
                          </span>
                        </button>
                      </div>
                      <img
                        src={brandLogo}
                        width="585"
                        height="113"
                        alt="Veta & Association Logo"
                        className="logo"
                      />
                    </div>
                    <div className="card-body">
                      <div className="row justify-content-between">
                        <div className="col-6"></div>
                        <div className="col-6 d-flex justify-content-end">
                          <img
                            src={back}
                            alt="back"
                            style={{ width: "10%", cursor: "pointer" }}
                            onClick={history.goBack}
                          />
                        </div>
                      </div>
                      <div className="d-block">
                        <Link
                          to="/createnewfolder"
                          className="btn btn-transparent d-flex align-items-center justify-content-center w-100"
                        >
                          <span className="account_icon">
                            <img src={createfolder} alt="Icon-folder-add" />
                          </span>
                          Add Folder
                        </Link>
                      </div>
                      <div className="row">
                        <div className="mx-auto">
                          <h1 className="text-center">Nothing Found</h1>
                          <img
                            src={nofolder}
                            alt="no-folder"
                            style={{ width: "80%" }}
                            className="pb-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  } else {
    return (
      <div id="main-content">
        <main className="dashBoard" id="main-wrapper">
          <div className="opacity_bg"></div>
          <div className="container-fluid">
            <div className="content">
              <div className="row">
                <DashBoardLeft />
                <div className="col-md-12 col-lg-9">
                  <div className="card card-border-radius">
                    <div className="card-header position-relative">
                      <div className="toggle-icon-wrapper">
                        <button
                          className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                          id="navbar_humburger"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          title="navbar_humburger"
                          data-bs-original-title="Toggle Navigation"
                          aria-label="Toggle Navigation"
                        >
                          <span className="navbar-toggle-icon">
                            <span className="toggle-line"></span>
                          </span>
                        </button>
                      </div>
                      <img
                        src={brandLogo}
                        width="585"
                        height="113"
                        alt="Veta & Association Logo"
                        className="logo"
                      />
                    </div>
                    <div className="card-body">
                      <div className="row justify-content-between">
                        <div className="col-6"></div>
                        <div className="col-6 d-flex justify-content-end">
                          <img
                            src={back}
                            alt="back"
                            style={{ width: "10%", cursor: "pointer" }}
                            onClick={history.goBack}
                          />
                        </div>
                      </div>
                      <div className="d-block">
                        <Link
                          to="/createnewfolder"
                          className="btn btn-transparent d-flex align-items-center justify-content-center w-100"
                        >
                          <span className="account_icon">
                            <img src={createfolder} alt="Icon-person-add" />
                          </span>
                          Add Folder
                        </Link>
                      </div>
                      <div className="table-responsive">
                        <table
                          className="table table-vcenter"
                          id="dataTable_files"
                        >
                          <thead>
                            <tr>
                              <th style={{ width: "40%" }}>Folder</th>
                              <th
                                style={{ width: "45%", cursor: "pointer" }}
                                onClick={sortfiles}
                              >
                                Created Date <span>&#8595;</span>
                                <span>&#8593;</span>
                              </th>
                              <th style={{ width: "45%" }}>View</th>
                              <th style={{ width: "45%" }}>Delete</th>
                            </tr>
                          </thead>
                          <tbody>{displayFolders}</tbody>
                        </table>
                      </div>
                      <div className="table_nav">
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"paginationBttns"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"paginationDisabled"}
                          activeClassName={"paginationActive"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
};

export default CustomerViewFolder;
