import React, { useState, useEffect } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandLogo from "../../images/brandlogo.png";
import {
  getUserID,
  getToken,
  getUserName,
  getFirstName,
  getLastName,
} from "../../utils/Common";
import { toast } from "react-toastify";
import back from "../../images/back.png";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const CustomerUploadFileMain = ({ match }, props) => {
  let history = useHistory();
  let data;
  const token = getToken();
  const id = getUserID();
  const username = getUserName();
  const uploadedByFullName = getFirstName() + " " + getLastName();
  const [folderName, setFolderName] = useState("");
  const [comments, setComments] = useState("");
  const [file, setFile] = useState("");
  const [fileLimit, setFileLimit] = useState(0);
  const [fileToUpload, setFileToUpload] = useState([]);
  const [loading, setLoading] = useState(false);

  const filehandler = (e) => {
    e.preventDefault();
    for (let i = 0; i < e.target.files.length; i++) {
      setFileToUpload((prevArray) => [
        ...prevArray,
        {
          File: e.target.files[i],
          FileName: e.target.files[i].name,
          Size: e.target.files[i].size,
          key: Math.random() * 50,
        },
      ]);
    }
    setFile("");
  };

  const commentsHandler = (e) => {
    setComments(e.target.value);
  };
  const deleteHandler = (key) => {
    setFileToUpload(fileToUpload.filter((item) => item.key !== key));
  };

  const displayFiles = fileToUpload.map((file) => {
    const size = Math.round(file.Size / 1000);
    return (
      <tr>
        <td style={{ wordWrap: "break-word", maxWidth: "20rem" }}>
          {file.FileName}
        </td>
        <td>{size} Kb</td>
        <td>
          <input
            type="button"
            value="Remove"
            onClick={() => {
              deleteHandler(file.key);
            }}
            className="btn button-primary"
            disabled={loading ? true : false}
          />
        </td>
      </tr>
    );
  });

  const checkForSpecialCharacter = async () => {
    const specialChars = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;
    for await (let [key, value] of Object.entries(fileToUpload)) {
      if (specialChars.test(value.FileName)) {
        toast.error(
          `The file name should only include “_” and “.” as special characters`
        );
        return true;
      }
    }
  };

  const fileSubmitter = async (e) => {
    e.preventDefault();
    if (fileToUpload.length < 0) {
      toast.error("Please Select Atleast One File Before Submitting.");
    } else {
      let result = await checkForSpecialCharacter();
      if (!result) {
        setLoading(true);
        checkFile();
      }
    }
  };

  const checkFile = async () => {
    const check = {
      FileName: fileToUpload,
    };
    await fetch(`https://vetacpav2.azurewebsites.net/api/file/check/${id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(check),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.Exist) {
          Swal.fire({
            title: "\n Duplicate File Upload",
            text: `We do have same file(s) in our storage. Details : ${responseJson.Exist}`,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Keep as seperate file",
            denyButtonText: `Replace`,
          }).then((result) => {
            if (result.isConfirmed) {
              renameFile();
            } else if (result.isDenied) {
              addFile();
            } else {
              setLoading(false);
            }
          });
          //window.confirm(`Duplicate File Upload. \n\nFile('s): ${responseJson.Exist}`);
        } else {
          addFile();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  const addFile = async () => {
    data = new FormData();
    data.append("CPAId", `${match.params.cpaid}`);
    data.append("FolderId", `${match.params.fileid}`);
    data.append("UserId", id);
    data.append("UserName", username);
    data.append("SendBy", "Client");
    data.append("Comments", comments);
    data.append("UploadedBy", uploadedByFullName);
    for (let i = 0; i < fileToUpload.length; i++) {
      data.append("File", fileToUpload[i].File);
    }
    await fetch(
      "https://vetacpav2.azurewebsites.net/api/file/upload/multiple",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message) {
          setLoading(false);
          toast.error(responseJson.message);
        } else if (responseJson.success) {
          setLoading(false);
          setFileToUpload([]);
          setComments("");
          toast.success(responseJson.success);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  const renameFile = async () => {
    data = new FormData();
    data.append("CPAId", `${match.params.cpaid}`);
    data.append("FolderId", `${match.params.fileid}`);
    data.append("UserId", id);
    data.append("UserName", username);
    data.append("SendBy", "Client");
    data.append("Comments", comments);
    data.append("UploadedBy", uploadedByFullName);
    for (let i = 0; i < fileToUpload.length; i++) {
      data.append("File", fileToUpload[i].File);
    }
    await fetch(
      "https://vetacpav2.azurewebsites.net/api/file/rename/multiple",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message) {
          setLoading(false);
          toast.error(responseJson.message);
        } else if (responseJson.success) {
          setLoading(false);
          setFileToUpload([]);
          setComments("");
          toast.success(responseJson.success);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    const getFolderName = async () => {
      const details = await fetch(
        `https://vetacpav2.azurewebsites.net/api/folder/${match.params.fileid}`,
        {
          headers: {
            Accept: "application/json",
            "auth-token": token,
            "user-id": id,
            "content-type": "application/json",
          },
        }
      );
      const data = await details.json();
      setFolderName(data[0].FolderName);
    };

    const getFileSize = async () => {
      const details = await fetch(
        `https://vetacpav2.azurewebsites.net/api/settings/limit/615e78ae7f696764418f1b55`,
        {
          headers: {
            Accept: "application/json",
            "auth-token": token,
            "user-id": id,
            "content-type": "application/json",
          },
        }
      );
      const data = await details.json();
      setFileLimit(data.FileSize);
    };
    getFolderName();
    getFileSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div id="main-content">
      <main className="dashBoard" id="main-wrapper">
        <div className="opacity_bg"></div>
        <div className="container-fluid">
          <div className="content">
            <div className="row">
              <DashBoardLeft />
              <div className="col-md-12 col-lg-9">
                <form onSubmit={fileSubmitter}>
                  <div className="card card-border-radius">
                    <div className="card-header position-relative">
                      <div className="toggle-icon-wrapper">
                        <button
                          className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                          id="navbar_humburger"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          title="navbar_humburger"
                          data-bs-original-title="Toggle Navigation"
                          aria-label="Toggle Navigation"
                        >
                          <span className="navbar-toggle-icon">
                            <span className="toggle-line"></span>
                          </span>
                        </button>
                      </div>
                      <img
                        src={brandLogo}
                        width="585"
                        height="113"
                        alt="Veta & Association Logo"
                        className="logo"
                      />
                    </div>

                    <div className="card-body">
                      <div className="row justify-content-between">
                        <div className="col-6"></div>
                        <div className="col-6 d-flex justify-content-end">
                          <img
                            src={back}
                            alt="back"
                            style={{ width: "10%", cursor: "pointer" }}
                            onClick={history.goBack}
                          />
                        </div>
                      </div>
                      <div className="row pb-2">
                        <div className="col-6">
                          <h4>
                            <b>
                              <u>Upload Files</u>
                            </b>
                          </h4>
                          <h5>Folder Name : {folderName}</h5>
                          <h5>Upload Limit: {fileLimit} MB</h5>
                          <input
                            id="upload"
                            type="file"
                            name="file"
                            value={file}
                            onChange={filehandler}
                            multiple
                          />
                        </div>
                        <div className="col-6">
                          <div class="form-group position-relative">
                            <label for="maxFileSize">Comments If Any:</label>
                            <textarea
                              type="text"
                              name="Comments"
                              value={comments}
                              id="maxFileSize"
                              class="form-control"
                              rows="4"
                              onChange={commentsHandler}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table
                              className="table table-vcenter"
                              id="dataTable_files"
                            >
                              <thead>
                                <tr>
                                  <th style={{ width: "20%" }}>File Name </th>
                                  <th style={{ width: "15%" }}>Size</th>
                                  <th style={{ width: "20%" }}>Remove</th>
                                </tr>
                              </thead>
                              <tbody>{displayFiles}</tbody>
                            </table>
                          </div>
                          <div className="table_nav"></div>
                        </div>
                      </div>
                      <div className="row pt-2 ml-5 mr-5 ">
                        <button
                          className="btn btn-block button-primary"
                          disabled={loading ? true : false}
                        >
                          {loading ? "Loading..." : "Upload All"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CustomerUploadFileMain;
