import React, { useState, useEffect } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandlogo from "../../images/brandlogo.png";
import { getUserID, getFirstName, getLastName } from "../../utils/Common";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import back from "../../images/back.png";
import Swal from "sweetalert2";

const AdminUploadFileModal = ({ match }, props) => {
  // const swal = require('sweetalert2');
  let data;
  let history = useHistory();
  const id = getUserID();
  const uploadedByFullName = getFirstName() + " " + getLastName();
  const [selectedFolder, setSelectedFolder] = useState("");
  const [file, setFile] = useState("");
  const [uploadtrue, setUploadtrue] = useState(true);
  const [comments, setComments] = useState("");
  const [fileToUpload, setFileToUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [userName, setUserName] = useState("");
  const [folderDetails, setFolderDetails] = useState([]);
  const [clientEmail, setClientEmail] = useState("");
  const [trueOrFalse, setTrueOrFalse] = useState(false);

  const commentsHandler = (e) => {
    setComments(e.target.value);
  };

  const folderHandler = (e) => {
    setSelectedFolder(e.target.value);
  };

  const getUserDetails = async () => {
    const details = await fetch(
      `https://vetacpav2.azurewebsites.net/api/customermaster/client/view/${match.params.id}`,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      }
    );
    const data = await details.json();
    setClientName(`${data.FirstName} ${data.LastName}`);
    setUserName(data.UserName);
    setClientEmail(data.Email);
  };

  const getFolderDetails = async () => {
    const details = await fetch(
      `https://vetacpav2.azurewebsites.net/api/folder/user/${match.params.id}`,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      }
    );
    const data = await details.json();
    setFolderDetails(data);
  };

  const deleteHandler = (key) => {
    setFileToUpload(fileToUpload.filter((item) => item.key !== key));
  };

  const displayFiles = fileToUpload.map((file) => {
    const size = Math.round(file.Size / 1000);
    return (
      <tr>
        <td style={{ wordWrap: "break-word", maxWidth: "20rem" }}>
          {file.FileName}
        </td>
        <td>
          {size}
          Kb
        </td>
        <td>
          <input
            type="button"
            value="Remove"
            onClick={() => {
              deleteHandler(file.key);
            }}
            className="btn button-primary"
            disabled={loading ? true : false}
          />
        </td>
      </tr>
    );
  });

  const checkForSpecialCharacter = async () => {
    const specialChars = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;
    for await (let [key, value] of Object.entries(fileToUpload)) {
      if (specialChars.test(value.FileName)) {
        toast.error(
          `The file name should only include “_” and “.” as special characters`
        );
        return true;
      }
    }
  };

  const filehandler = (e) => {
    e.preventDefault();
    for (let i = 0; i < e.target.files.length; i++) {
      setFileToUpload((prevArray) => [
        ...prevArray,
        {
          File: e.target.files[i],
          FileName: e.target.files[i].name,
          Size: e.target.files[i].size,
          key: Math.random() * 50,
        },
      ]);
    }
    setFile("");
  };

  useEffect(() => {
    getUserDetails();
    getFolderDetails();
    return () => {
      setUploadtrue(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadtrue]);

  const fileSubmitter = async (e) => {
    e.preventDefault();
    if (fileToUpload.length < 0) {
      toast.error("Please Select Atleast One File Before Submitting.");
    } else if (selectedFolder === "") {
      toast.error("Please Select A Folder.");
    } else {
      let result = await checkForSpecialCharacter();
      if (!result) {
        setLoading(true);
        checkFile();
      }
      // addFile();
    }
  };

  const checkFile = async () => {
    const check = {
      FileName: fileToUpload,
    };
    await fetch(
      `https://vetacpav2.azurewebsites.net/api/file/check/${match.params.id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify(check),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.Exist) {
          Swal.fire({
            title: "\n Duplicate File Upload",
            text: `We do have same file(s) in our storage. Details : ${responseJson.Exist}`,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Keep as seperate file",
            denyButtonText: `Replace`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              renameFile();
            } else if (result.isDenied) {
              addFile();
            } else {
              setLoading(false);
            }
          });
          // window.confirm(`Duplicate File Upload. \n\nFile('s): ${responseJson.Exist}`);
        } else {
          addFile();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  const addFile = async () => {
    data = new FormData();
    data.append("CPAId", id);
    data.append("FolderId", selectedFolder);
    data.append("UserId", `${match.params.id}`);
    data.append("UserName", userName);
    data.append("SendBy", "CPA");
    data.append("UploadedBy", uploadedByFullName);
    data.append("Comments", comments);
    for (let i = 0; i < fileToUpload.length; i++) {
      data.append("File", fileToUpload[i].File);
    }
    await fetch(
      "https://vetacpav2.azurewebsites.net/api/file/upload/multiple",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message) {
          setLoading(false);
          toast.error(responseJson.message);
        } else if (responseJson.success) {
          setLoading(false);
          setUploadtrue(true);
          setFileToUpload([]);
          setComments("");
          setSelectedFolder("");
          toast.success(responseJson.success);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  const renameFile = async () => {
    data = new FormData();
    data.append("CPAId", id);
    data.append("FolderId", selectedFolder);
    data.append("UserId", `${match.params.id}`);
    data.append("UserName", userName);
    data.append("SendBy", "CPA");
    data.append("UploadedBy", uploadedByFullName);
    data.append("Comments", comments);
    for (let i = 0; i < fileToUpload.length; i++) {
      data.append("File", fileToUpload[i].File);
    }
    await fetch(
      "https://vetacpav2.azurewebsites.net/api/file/rename/multiple",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message) {
          setLoading(false);
          toast.error(responseJson.message);
        } else if (responseJson.success) {
          setUploadtrue(true);
          setLoading(false);
          setFileToUpload([]);
          setComments("");
          setSelectedFolder("");
          toast.success(responseJson.success);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  return (
    <div id="main-content">
      <main className="dashBoard" id="main-wrapper">
        <div className="opacity_bg"></div>
        <div className="container-fluid">
          <div className="content">
            <div className="row">
              <DashBoardLeft />
              <div className="col-md-12 col-lg-9">
                <div className="card card-border-radius">
                  <div className="card-header position-relative">
                    <div className="toggle-icon-wrapper">
                      <button
                        className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                        id="navbar_humburger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="navbar_humburger"
                        data-bs-original-title="Toggle Navigation"
                        aria-label="Toggle Navigation"
                      >
                        <span className="navbar-toggle-icon">
                          <span className="toggle-line"></span>
                        </span>
                      </button>
                    </div>

                    <img
                      src={brandlogo}
                      width="585"
                      height="113"
                      alt="Veta & Association Logo"
                      className="logo"
                    />
                  </div>
                  <div className="card-body">
                    <div className="row justify-content-between">
                      <div className="col-6"></div>
                      <div className="col-6 d-flex justify-content-end">
                        <img
                          src={back}
                          alt="back"
                          style={{
                            width: "10%",
                            cursor: "pointer",
                          }}
                          onClick={history.goBack}
                        />
                      </div>
                    </div>
                    <h2>
                      Upload Files
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="33.098"
                          height="33.093"
                          viewBox="0 0 33.098 33.093"
                        >
                          <path
                            id="Icon_awesome-upload"
                            data-name="Icon awesome-upload"
                            d="M19.135,24.824H13.963a1.548,1.548,0,0,1-1.551-1.551V12.412H6.742a1.29,1.29,0,0,1-.911-2.2L15.663.369a1.252,1.252,0,0,1,1.765,0l9.839,9.839a1.29,1.29,0,0,1-.911,2.2H20.686v10.86A1.548,1.548,0,0,1,19.135,24.824ZM33.1,24.307v7.24A1.548,1.548,0,0,1,31.546,33.1H1.551A1.548,1.548,0,0,1,0,31.547v-7.24a1.548,1.548,0,0,1,1.551-1.551h8.792v.517a3.622,3.622,0,0,0,3.62,3.62h5.172a3.622,3.622,0,0,0,3.62-3.62v-.517h8.792A1.548,1.548,0,0,1,33.1,24.307ZM25.082,30a1.293,1.293,0,1,0-1.293,1.293A1.3,1.3,0,0,0,25.082,30Zm4.137,0a1.293,1.293,0,1,0-1.293,1.293A1.3,1.3,0,0,0,29.219,30Z"
                            transform="translate(0 -0.005)"
                            fill="#b30000"
                          />
                        </svg>
                      </span>
                    </h2>
                    <p>Select folder and files to upload</p>
                    <p>
                      The file name should only include “_” and “.” as special
                      characters
                    </p>
                    <div className="row pb-2 pl-3">
                      <b>
                        <h4>Client Name : {clientName}</h4>
                        <h4 className="pt-2">User Name : {userName}</h4>
                        <h4 className="pt-2 pb-2">Email : {clientEmail}</h4>
                      </b>
                    </div>
                    <form onSubmit={fileSubmitter}>
                      <div className="form-group position-relative">
                        <label for="folderSelect">Select folder</label>
                        <select
                          className="form-control"
                          id="folderSelect"
                          aria-label="Default select example"
                          name="FolderId"
                          value={selectedFolder}
                          onChange={folderHandler}
                          required="true"
                        >
                          <option disabled selected value="">
                            {" "}
                            -- select an option --{" "}
                          </option>
                          {folderDetails.map((folder) => {
                            return (
                              <option value={folder._id}>
                                {folder.FolderName}{" "}
                              </option>
                            );
                          })}{" "}
                        </select>
                        <div className="pt-5">
                          <input
                            id="upload"
                            type="file"
                            name="file"
                            value={file}
                            onChange={filehandler}
                            multiple
                          />
                        </div>
                        <div className="pt-4">
                          <div class="form-group position-relative">
                            <label for="maxFileSize">Comments If Any:</label>
                            <textarea
                              type="text"
                              name="Comments"
                              value={comments}
                              id="maxFileSize"
                              class="form-control"
                              rows="2"
                              onChange={commentsHandler}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table
                          className="table table-vcenter"
                          id="dataTable_files"
                        >
                          <thead>
                            <tr>
                              <th style={{ width: "20%" }}>File Name</th>
                              <th style={{ width: "15%" }}>Size</th>
                              <th style={{ width: "20%" }}>Remove</th>
                            </tr>
                          </thead>
                          <tbody>{displayFiles}</tbody>
                        </table>
                      </div>
                      <button
                        type="submit"
                        id="updateBtn"
                        className="btn btn-block button-primary"
                        disabled={loading ? true : false}
                      >
                        {loading ? "Loading..." : "Upload All"}{" "}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminUploadFileModal;
