import React, { useState } from "react";
import LeftImage from "../LeftMainComponent/LeftImage";
import brandlogo from "../../images/brandlogo.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const SignUp = () => {
  const [input, setInput] = useState({
    Email: "",
    FirstName: "",
    UserName: "",
    LastName: "",
    Password: "",
    ConfirmPassword: "",
  });
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const signupAPI = async (props) => {
    await fetch("https://vetacpav2.azurewebsites.net/api/user/signup", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify(input),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message) {
          setLoading(false);
          toast.error(responseJson.message);
        } else {
          setLoading(false);
          toast.success(responseJson.success);
          setSuccessMessage(responseJson.success);
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setError(null);
  };

  const showpass = (e) => {
    setShowPassword(!showPassword);
  };

  const showCnfrmPass = (e) => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const formSubmitter = (e) => {
    e.preventDefault();
    setSuccessMessage(null);
    setError(null);
    setLoading(true);
    signupAPI();
  };
  return (
    <main>
      <div className="container-fluid">
        <div className="row min-vh-100">
          <LeftImage />
          <div className="col-lg-6 col-md-6">
            <div className="signup-section-wrapper min-vh-100 px-md-3 px-0">
              <div className="brand-wrapper">
                <img
                  src={brandlogo}
                  width="585"
                  height="113"
                  alt="Veta & Association Logo"
                  className="logo"
                />
              </div>

              <div className="signup-wrapper">
                <form onSubmit={formSubmitter} id="signup_form">
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group position-relative">
                        <label for="firstname">
                          First name
                          <span className="smiley_icon d-none">
                            <img
                              src="assets/images/icon_smile.png"
                              alt="Smile Icon"
                              width="22"
                              height="22"
                            />
                          </span>
                        </label>
                        <input
                          type="text"
                          name="FirstName"
                          id="firstname"
                          className="form-control"
                          placeholder="Enter Your First Name"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group position-relative">
                        <label for="lastname">
                          Last name{" "}
                          <span className="smiley_icon d-none">
                            {" "}
                            <img
                              src="assets/images/icon_smile.png"
                              alt="Smile Icon"
                              width="22"
                              height="22"
                            />{" "}
                          </span>
                        </label>
                        <input
                          type="text"
                          name="LastName"
                          id="lastname"
                          className="form-control"
                          placeholder="Enter Your Last Name"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group position-relative">
                        <label for="username">
                          Username{" "}
                          <span className="smiley_icon d-none">
                            {" "}
                            <img
                              src="assets/images/icon_smile.png"
                              alt="Smile Icon"
                              width="22"
                              height="22"
                            />{" "}
                          </span>
                        </label>
                        <input
                          type="text"
                          name="UserName"
                          id="username"
                          className="form-control"
                          placeholder="Enter Your User Name"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group position-relative">
                        <label for="email">
                          Email address{" "}
                          <span className="smiley_icon d-none">
                            {" "}
                            <img
                              src="assets/images/icon_smile.png"
                              alt="Smile Icon"
                              width="22"
                              height="22"
                            />{" "}
                          </span>
                        </label>
                        <input
                          type="email"
                          name="Email"
                          id="email"
                          className="form-control"
                          placeholder="Enter Your Email ID"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group position-relative mb-3">
                        <label for="password">
                          Password{" "}
                          <span className="smiley_icon d-none">
                            {" "}
                            <img
                              src="assets/images/icon_smile.png"
                              alt="Smile Icon"
                              width="22"
                              height="22"
                            />{" "}
                          </span>
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          name="Password"
                          id="password"
                          className="form-control passwordType"
                          placeholder="Enter Your password"
                          onChange={handleChange}
                        />
                        <div className="show_password" onClick={showpass}>
                          <span className="show_icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              aria-hidden="true"
                              role="img"
                              width="1em"
                              height="1em"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 24 24"
                            >
                              <g fill="currentColor">
                                <circle cx="12" cy="12" r="1.5"></circle>
                                <path d="M15.29 18.12L14 16.78l-.07-.07l-1.27-1.27a4.07 4.07 0 0 1-.61.06A3.5 3.5 0 0 1 8.5 12a4.07 4.07 0 0 1 .06-.61l-2-2L5 7.87a15.89 15.89 0 0 0-2.87 3.63a1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25a9.48 9.48 0 0 0 3.23-.67zM8.59 5.76l2.8 2.8A4.07 4.07 0 0 1 12 8.5a3.5 3.5 0 0 1 3.5 3.5a4.07 4.07 0 0 1-.06.61l2.68 2.68l.84.84a15.89 15.89 0 0 0 2.91-3.63a1 1 0 0 0 0-1c-.64-1.11-4.16-6.68-10.14-6.5a9.48 9.48 0 0 0-3.23.67zm12.12 13.53L19.41 18l-2-2l-9.52-9.53L6.42 5L4.71 3.29a1 1 0 0 0-1.42 1.42L5.53 7l1.75 1.7l7.31 7.3l.07.07L16 17.41l.59.59l2.7 2.71a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42z"></path>
                              </g>
                            </svg>
                          </span>

                          <span className="hide_icon" onClick={showpass}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              aria-hidden="true"
                              role="img"
                              width="1em"
                              height="1em"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 24 24"
                            >
                              <g fill="currentColor">
                                <circle cx="12" cy="12" r="1.5"></circle>
                                <path d="M21.87 11.5c-.64-1.11-4.16-6.68-10.14-6.5c-5.53.14-8.73 5-9.6 6.5a1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25c5.53-.14 8.74-5 9.6-6.5a1 1 0 0 0 0-1zm-9.87 4a3.5 3.5 0 1 1 3.5-3.5a3.5 3.5 0 0 1-3.5 3.5z"></path>
                              </g>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group position-relative mb-3">
                        <label for="confPassword">
                          Confirm Password{" "}
                          <span className="smiley_icon d-none">
                            {" "}
                            <img
                              src="assets/images/icon_smile.png"
                              alt="Smile Icon"
                              width="22"
                              height="22"
                            />{" "}
                          </span>
                        </label>
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          name="ConfirmPassword"
                          id="confPassword"
                          className="form-control confPwdType"
                          placeholder="enter your password"
                          onChange={handleChange}
                        />
                        <div className="confPsw_show" onClick={showCnfrmPass}>
                          <span className="show_icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              aria-hidden="true"
                              role="img"
                              width="1em"
                              height="1em"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 24 24"
                            >
                              <g fill="currentColor">
                                <circle cx="12" cy="12" r="1.5"></circle>
                                <path d="M15.29 18.12L14 16.78l-.07-.07l-1.27-1.27a4.07 4.07 0 0 1-.61.06A3.5 3.5 0 0 1 8.5 12a4.07 4.07 0 0 1 .06-.61l-2-2L5 7.87a15.89 15.89 0 0 0-2.87 3.63a1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25a9.48 9.48 0 0 0 3.23-.67zM8.59 5.76l2.8 2.8A4.07 4.07 0 0 1 12 8.5a3.5 3.5 0 0 1 3.5 3.5a4.07 4.07 0 0 1-.06.61l2.68 2.68l.84.84a15.89 15.89 0 0 0 2.91-3.63a1 1 0 0 0 0-1c-.64-1.11-4.16-6.68-10.14-6.5a9.48 9.48 0 0 0-3.23.67zm12.12 13.53L19.41 18l-2-2l-9.52-9.53L6.42 5L4.71 3.29a1 1 0 0 0-1.42 1.42L5.53 7l1.75 1.7l7.31 7.3l.07.07L16 17.41l.59.59l2.7 2.71a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42z"></path>
                              </g>
                            </svg>
                          </span>

                          <span className="hide_icon" onClick={showCnfrmPass}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              aria-hidden="true"
                              role="img"
                              width="1em"
                              height="1em"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 24 24"
                            >
                              <g fill="currentColor">
                                <circle cx="12" cy="12" r="1.5"></circle>
                                <path d="M21.87 11.5c-.64-1.11-4.16-6.68-10.14-6.5c-5.53.14-8.73 5-9.6 6.5a1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25c5.53-.14 8.74-5 9.6-6.5a1 1 0 0 0 0-1zm-9.87 4a3.5 3.5 0 1 1 3.5-3.5a3.5 3.5 0 0 1-3.5 3.5z"></path>
                              </g>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group position-relative mb-0 pt-3">
                    {error && (
                      <h6 className="text-center" style={{ color: "#C80707" }}>
                        {error}
                      </h6>
                    )}
                  </div>
                  <div className="form-group position-relative mb-0 pt-3">
                    {successMessage && (
                      <h6 className="text-center" style={{ color: "#C80707" }}>
                        {successMessage} <Link to="/"> Login</Link>
                      </h6>
                    )}
                  </div>
                  <button
                    id="signUp"
                    className="btn btn-block button-primary login-btn"
                    disabled={loading ? true : false}
                  >
                    {loading ? "Loading..." : "Sign Up"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignUp;
