import React, { useState, useEffect } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandLogo from "../../images/brandlogo.png";
import editIcon from "../../images/editicon.png";
import addClient from "../../images/addclient.png";
import { Link } from "react-router-dom";
import deleteIcon from "../../images/deleteicon.png";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { getToken } from "../../utils/Common";
import check from "../../images/check.png";
import close from "../../images/close.png";

const AdminManageCPA = () => {
  const token = getToken();
  let cpaid;
  const [userDetails, setUserDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCpa = async () => {
    const details = await fetch(
      `https://vetacpav2.azurewebsites.net/api/cpa/`,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      }
    );
    const data = await details.json();
    setUserDetails(data);
  };

  const toggleClientStatus = (e, value) => {
    cpaid = e.currentTarget.value;
    //change status
    const toggleStatusApi = async () => {
      await fetch(
        `https://vetacpav2.azurewebsites.net/api/customermaster/cpa/togglestatus/${cpaid}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "auth-token": token,
            "content-type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message) {
            setLoading(false);
            toast.error(responseJson.message);
          } else if (responseJson.success) {
            setLoading(false);
            toast.success(responseJson.success);
            getCpa();
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.message);
        });
    };
    Swal.fire({
      title: "\n Change Client Status?",
      text: `${value}`,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Toggle Status",
      denyButtonText: ``,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true);
        toggleStatusApi();
      } else {
        setLoading(false);
      }
    });
  };

  const makeAsAdmin = (e, value) => {
    cpaid = e.currentTarget.value;
    //change status
    const makeAsAdminAPI = async () => {
      await fetch(
        `https://vetacpav2.azurewebsites.net/api/customermaster/cpa/makeasadmin/${cpaid}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "auth-token": token,
            "content-type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message) {
            setLoading(false);
            toast.error(responseJson.message);
          } else if (responseJson.success) {
            setLoading(false);
            toast.success(responseJson.success);
            getCpa();
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.message);
        });
    };
    Swal.fire({
      title: `\n Are You Sure To Toggle The User Type Of ${value} ?`,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "YES",
      denyButtonText: ``,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true);
        makeAsAdminAPI();
      } else {
        setLoading(false);
      }
    });
  };

  const displayCPA = userDetails.map((cpa) => {
    let status = cpa.Status;
    let role = cpa.TypeOfUser;
    if (status === 1 && role === "Admin") {
      return (
        <tr>
          <td>
            {cpa.FirstName} {cpa.LastName}
          </td>
          <td>{cpa.Email}</td>
          <td style={{ color: "green" }}>Active</td>
          <td>
            <Link
              to={`/managecpa/${cpa._id}`}
              type="button"
              className="action_btn editData mr-3"
              title="Edit"
            >
              <img src={editIcon} alt="edit_icon" width="21" height="18" />
            </Link>
            <button
              type="button"
              className="action_btn deleteData"
              title="Delete"
              onClick={(e) => {
                toggleClientStatus(e, `${cpa.FirstName} ${cpa.LastName}`);
              }}
              value={cpa._id}
            >
              <img src={deleteIcon} alt="delete_icon" width="14" height="18" />
            </button>
          </td>
          <td>
            <button
              type="button"
              className="action_btn deleteData"
              title="Change To CPA"
              onClick={(e) => {
                makeAsAdmin(e, `${cpa.FirstName} ${cpa.LastName}`);
              }}
              value={cpa._id}
            >
              <img
                src={close}
                className="text-center"
                alt="remove from admin"
                width="14"
                height="18"
              />
            </button>
          </td>
        </tr>
      );
    } else if (status === 1 && role === "CPA") {
      return (
        <tr>
          <td>
            {cpa.FirstName} {cpa.LastName}
          </td>
          <td>{cpa.Email}</td>
          <td style={{ color: "green" }}>Active</td>
          <td>
            <Link
              to={`/managecpa/${cpa._id}`}
              type="button"
              className="action_btn editData mr-3"
              title="Edit"
            >
              <img src={editIcon} alt="edit_icon" width="21" height="18" />
            </Link>
            <button
              type="button"
              className="action_btn deleteData"
              title="Delete"
              onClick={(e) => {
                toggleClientStatus(e, `${cpa.FirstName} ${cpa.LastName}`);
              }}
              value={cpa._id}
            >
              <img src={deleteIcon} alt="delete_icon" width="14" height="18" />
            </button>
          </td>
          <td>
            <button
              type="button"
              className="action_btn deleteData"
              title="Change To Admin"
              onClick={(e) => {
                makeAsAdmin(e, `${cpa.FirstName} ${cpa.LastName}`);
              }}
              value={cpa._id}
            >
              <img
                src={check}
                className="text-center"
                alt="make as admin"
                width="14"
                height="18"
              />
            </button>
          </td>
        </tr>
      );
    } else if (status === 0 && role === "CPA") {
      return (
        <tr>
          <td>
            {cpa.FirstName} {cpa.LastName}
          </td>
          <td>{cpa.Email}</td>
          <td style={{ color: "red" }}>In-Active</td>
          <td>
            <Link
              to={`/managecpa/${cpa._id}`}
              type="button"
              className="action_btn editData mr-3"
              title="Edit"
            >
              <img src={editIcon} alt="edit_icon" width="21" height="18" />
            </Link>
            <button
              type="button"
              className="action_btn deleteData"
              title="Delete"
              onClick={(e) => {
                toggleClientStatus(e, `${cpa.FirstName} ${cpa.LastName}`);
              }}
              value={cpa._id}
            >
              <img src={deleteIcon} alt="delete_icon" width="14" height="18" />
            </button>
          </td>
          <td>
            <button
              type="button"
              className="action_btn deleteData"
              title="Change To Admin"
              onClick={(e) => {
                makeAsAdmin(e, `${cpa.FirstName} ${cpa.LastName}`);
              }}
              value={cpa._id}
            >
              <img src={check} alt="mark_as_admin" width="14" height="18" />
            </button>
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td>
            {cpa.FirstName} {cpa.LastName}
          </td>
          <td>{cpa.Email}</td>
          <td style={{ color: "red" }}>In-Active</td>
          <td>
            <Link
              to={`/managecpa/${cpa._id}`}
              type="button"
              className="action_btn editData mr-3"
              title="Edit"
            >
              <img src={editIcon} alt="edit_icon" width="21" height="18" />
            </Link>
            <button
              type="button"
              className="action_btn deleteData"
              title="Delete"
              onClick={(e) => {
                toggleClientStatus(e, `${cpa.FirstName} ${cpa.LastName}`);
              }}
              value={cpa._id}
            >
              <img src={deleteIcon} alt="delete_icon" width="14" height="18" />
            </button>
          </td>
          <td>
            <button
              type="button"
              className="action_btn deleteData"
              title="Change To CPA"
              onClick={(e) => {
                makeAsAdmin(e, `${cpa.FirstName} ${cpa.LastName}`);
              }}
              value={cpa._id}
            >
              <img
                src={close}
                className="text-center"
                alt="remove from admin"
                width="14"
                height="18"
              />
            </button>
          </td>
        </tr>
      );
    }
  });

  useEffect(() => {
    getCpa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="main-content">
      <main className="dashBoard" id="main-wrapper">
        <div className="opacity_bg"></div>
        <div className="container-fluid">
          <div className="content">
            <div className="row">
              <DashBoardLeft />
              <div className="col-md-12 col-lg-9">
                <div className="card card-border-radius">
                  <div className="card-header position-relative">
                    <div className="toggle-icon-wrapper">
                      <button
                        className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                        id="navbar_humburger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="navbar_humburger"
                        data-bs-original-title="Toggle Navigation"
                        aria-label="Toggle Navigation"
                      >
                        <span className="navbar-toggle-icon">
                          <span className="toggle-line"></span>
                        </span>
                      </button>
                    </div>
                    <img
                      src={brandLogo}
                      width="585"
                      height="113"
                      alt="Veta & Association Logo"
                      className="logo"
                    />
                  </div>
                  <div className="card-body">
                    <div className="d-block">
                      <Link
                        to="/addcpa"
                        className="btn btn-transparent d-flex align-items-center justify-content-center w-100"
                      >
                        <span className="account_icon">
                          <img src={addClient} alt="Icon-person-add" />
                        </span>{" "}
                        Add CPA
                      </Link>
                    </div>
                    <div className="table-responsive">
                      <table
                        className="table table-vcenter"
                        id="dataTable_files"
                      >
                        <thead>
                          <tr>
                            <th style={{ width: "20%" }}>CPA</th>
                            <th style={{ width: "20%" }}>Email</th>
                            <th style={{ width: "20%" }}>Status</th>
                            <th style={{ width: "15%" }}>Actions</th>
                            <th style={{ width: "20%" }}>Make As Admin</th>
                          </tr>
                        </thead>
                        <tbody>{displayCPA}</tbody>
                      </table>
                    </div>
                    <div className="table_nav"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminManageCPA;
