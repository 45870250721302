import React, { useState, useEffect } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandLogo from "../../images/brandlogo.png";
import viewIcon from "../../images/view.png";
import editIcon from "../../images/editicon.png";
import toggleIcon from "../../images/toggle.png";
import addClient from "../../images/addclient.png";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { getToken } from "../../utils/Common";
import Loading from "./Loading";

const AdminManageClients = () => {
  const token = getToken();
  let clientid;
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [customerPerPage, setCustomerPerPage] = useState(10);
  const pageVisited = pageNumber * customerPerPage;

  const sortAlphabetically = () => {
    if (sorting === true) {
      const sorted = userDetails.sort((a, b) => {
        return `${a.FirstName} ${a.LastName}`.localeCompare(
          `${b.FirstName} ${b.LastName}`
        );
      });
      setUserDetails(sorted);
      setSorting(false);
    } else {
      const sorted = userDetails.sort((a, b) => {
        return `${b.FirstName} ${b.LastName}`.localeCompare(
          `${a.FirstName} ${a.LastName}`
        );
      });
      setUserDetails(sorted);
      setSorting(true);
    }
  };

  const getClients = async () => {
    const details = await fetch(
      `https://vetacpav2.azurewebsites.net/api/customermaster/view/client`,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      }
    );
    const data = await details.json();
    setUserDetails(data);
  };

  const toggleClientStatus = (e, value) => {
    clientid = e.currentTarget.value;
    //change status
    const toggleStatusApi = async () => {
      await fetch(
        `https://vetacpav2.azurewebsites.net/api/customermaster/client/togglestatus/${clientid}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "auth-token": token,
            "content-type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message) {
            setLoading(false);
            toast.error(responseJson.message);
          } else if (responseJson.success) {
            setLoading(false);
            toast.success(responseJson.success);
            getClients();
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.message);
        });
    };
    Swal.fire({
      title: "\n Change Client Status?",
      text: `${value}`,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Toggle Status",
      denyButtonText: ``,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true);
        toggleStatusApi();
      } else {
        setLoading(false);
      }
    });
  };
  let fullname;
  const displayClients = userDetails
    .filter((customer) => {
      fullname = `${customer.FirstName} ${customer.LastName}`;
      if (searchTerm === "") {
        return customer;
      } else if (
        (customer = fullname.toLowerCase().includes(searchTerm.toLowerCase()))
      ) {
        return customer;
      } else {
        return customer;
      }
    })
    .slice(pageVisited, pageVisited + customerPerPage)
    .map((customer) => {
      let status = customer.Status;
      if (status === 1) {
        return (
          <tr>
            <td>
              {customer.FirstName} {customer.LastName}
            </td>
            <td>{customer.Email}</td>
            <td style={{ color: "green" }}>Active</td>
            <td>
              <Link to={`/admin/manageclient/viewfolders/${customer._id}`}>
                <img
                  src={viewIcon}
                  alt="view_icon"
                  className="ml-3"
                  width="21"
                  height="18"
                />
              </Link>
            </td>
            <td>
              <Link
                to={`/manageclient/${customer._id}`}
                type="button"
                className="action_btn editData mr-3"
                title="Manage"
              >
                <img src={editIcon} alt="edit_icon" width="21" height="18" />
              </Link>
              <button
                type="button"
                className="action_btn deleteData"
                title="Toggle Status"
                onClick={(e) => {
                  toggleClientStatus(
                    e,
                    `${customer.FirstName} ${customer.LastName}`
                  );
                }}
                value={customer._id}
              >
                <img
                  src={toggleIcon}
                  alt="toggle-icon"
                  width="24"
                  height="24"
                />
              </button>
            </td>
          </tr>
        );
      } else {
        return (
          <tr>
            <td>
              {customer.FirstName} {customer.LastName}
            </td>
            <td>{customer.Email}</td>
            <td style={{ color: "red" }}>In-Active</td>
            <td>
              <Link to={`/admin/manageclient/viewfolders/${customer._id}`}>
                <img
                  src={viewIcon}
                  alt="view_icon"
                  className="ml-3"
                  width="21"
                  height="18"
                />
              </Link>
            </td>
            <td>
              <Link
                to={`/manageclient/${customer._id}`}
                type="button"
                className="action_btn editData mr-3"
                title="Manage"
              >
                <img src={editIcon} alt="edit_icon" width="21" height="18" />
              </Link>
              <button
                type="button"
                className="action_btn deleteData"
                title="Toggle Status"
                onClick={(e) => {
                  toggleClientStatus(
                    e,
                    `${customer.FirstName} ${customer.LastName}`
                  );
                }}
                value={customer._id}
              >
                <img
                  src={toggleIcon}
                  alt="toggle-icon"
                  width="24"
                  height="24"
                />
              </button>
            </td>
          </tr>
        );
      }
    });

  const pageCount = Math.ceil(userDetails.length / customerPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading === "true") {
    return <Loading />;
  } else {
    return (
      <div id="main-content">
        <main className="dashBoard" id="main-wrapper">
          <div className="opacity_bg"></div>
          <div className="container-fluid">
            <div className="content">
              <div className="row">
                <DashBoardLeft />
                <div className="col-md-12 col-lg-9">
                  <div className="card card-border-radius">
                    <div className="card-header position-relative">
                      <div className="toggle-icon-wrapper">
                        <button
                          className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                          id="navbar_humburger"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          title="navbar_humburger"
                          data-bs-original-title="Toggle Navigation"
                          aria-label="Toggle Navigation"
                        >
                          <span className="navbar-toggle-icon">
                            <span className="toggle-line"></span>
                          </span>
                        </button>
                      </div>
                      <img
                        src={brandLogo}
                        width="585"
                        height="113"
                        alt="Veta & Association Logo"
                        className="logo"
                      />
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-4">
                          <div className="d-block">
                            <Link
                              to="/addclient"
                              className="btn btn-transparent d-flex align-items-center justify-content-center w-100"
                            >
                              <span className="account_icon">
                                <img src={addClient} alt="Icon-person-add" />
                              </span>
                              Add client
                            </Link>
                          </div>
                        </div>
                        <div className="col-3">
                          <label>No Of Clients Per Page</label>
                          <select
                            name="customerPerPage"
                            className="form-control"
                            onChange={(e) => {
                              setCustomerPerPage(e.currentTarget.value);
                            }}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                        <div className="col-5">
                          <input
                            type="text"
                            placeholder="search"
                            onChange={(event) => {
                              setSearchTerm(event.target.value);
                            }}
                            className="form-control float-right client_item client_search"
                          />
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table
                          className="table table-vcenter"
                          id="dataTable_files"
                        >
                          <thead>
                            <tr>
                              <th
                                style={{ width: "20%", cursor: "pointer" }}
                                onClick={sortAlphabetically}
                              >
                                Full Name <span>&#8595;</span>
                                <span>&#8593;</span>
                              </th>
                              <th style={{ width: "20%" }}>Email</th>
                              <th style={{ width: "20%" }}>Status</th>
                              <th style={{ width: "20%" }}>Folders</th>
                              <th style={{ width: "15%" }}>Actions</th>
                            </tr>
                          </thead>
                          <tbody>{displayClients}</tbody>
                        </table>
                      </div>
                      <div className="table_nav">
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"paginationBttns"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"paginationDisabled"}
                          activeClassName={"paginationActive"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
};

export default AdminManageClients;
