import React, { useState } from "react";
import LeftImage from "../LeftMainComponent/LeftImage";
import brandlogo from "../../images/brandlogo.png";
import { Link } from "react-router-dom";
import { forgetPassCredentials } from "../../utils/Common";
import { toast } from "react-toastify";

const ForgetPassword = (props) => {
  const [input, setInput] = useState({ Email: "" });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const forgetPassword = async () => {
    await fetch(
      "https://vetacpav2.azurewebsites.net/api/password/forgetpassword",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify(input),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message) {
          setLoading(false);
          toast.error(responseJson.message);
        } else {
          setLoading(false);
          forgetPassCredentials(responseJson.email, responseJson.code);
          props.history.push("/changepassword");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };
  const formSubmitter = (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    forgetPassword();
  };

  return (
    <main>
      <div className="container-fluid">
        <div className="row min-vh-100">
          <LeftImage />
          <div className="col-lg-6 col-md-6">
            <div className="login-section-wrapper min-vh-100 px-md-3 px-0">
              <div className="brand-wrapper">
                <img
                  src={brandlogo}
                  width="585"
                  height="113"
                  alt="Veta & Association Logo"
                  className="logo"
                />
              </div>

              <div className="login-wrapper">
                <h1 className="signin-title">Forgot your password?</h1>
                <h6>
                  Please provide your email id associated with the account
                </h6>

                <div className="divider" role="separator"></div>
                <form onSubmit={formSubmitter}>
                  <div className="form-group position-relative">
                    <label for="email">Email</label>
                    <input
                      type="text"
                      name="Email"
                      id="email"
                      className="form-control"
                      placeholder="Enter Your Email ID"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group position-relative mb-0 pt-3">
                    {error && (
                      <h6 className="text-center" style={{ color: "#C80707" }}>
                        {error}
                      </h6>
                    )}
                  </div>
                  <button
                    id="signUp"
                    className="btn btn-block button-primary login-btn"
                    disabled={loading ? true : false}
                  >
                    {loading ? "Loading..." : "Submit"}
                  </button>
                </form>
                <div className="text-center">
                  Have an account..?
                  <Link to="/" className="forgot-password-link">
                    Login
                  </Link>{" "}
                  here.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgetPassword;
