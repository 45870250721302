import React, { useState, useEffect } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandlogo from "../../images/brandlogo.png";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
const AdminDashBoard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [userData, setUserData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [sorting, setSorting] = useState(true);

  const sortAlphabetically = () => {
    if (sorting === true) {
      const sorted = userData.sort((a, b) => {
        return `${a.FirstName} ${a.LastName}`.localeCompare(
          `${b.FirstName} ${b.LastName}`
        );
      });
      setUserData(sorted);
      setSorting(false);
    } else {
      const sorted = userData.sort((a, b) => {
        return `${b.FirstName} ${b.LastName}`.localeCompare(
          `${a.FirstName} ${a.LastName}`
        );
      });
      setUserData(sorted);
      setSorting(true);
    }
  };

  useEffect(() => {
    const getClients = async () => {
      const details = await fetch(
        `https://vetacpav2.azurewebsites.net/api/customermaster/view/client`,
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },
        }
      );
      const data = await details.json();
      setUserData(data);
    };
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customerPerPage = 12;
  const pageVisited = pageNumber * customerPerPage;
  let fullname;
  const displayClients = userData
    .filter((customer) => {
      fullname = `${customer.FirstName} ${customer.LastName}`;
      if (searchTerm === "") {
        return customer;
      } else if (
        (customer = fullname.toLowerCase().includes(searchTerm.toLowerCase()))
      ) {
        return customer;
      } else {
        return customer;
      }
    })
    .slice(pageVisited, pageVisited + customerPerPage)
    .map((customer) => {
      return (
        <tr className="text-justify">
          <td>
            <Link
              to={`/adminuploadfile/${customer._id}`}
              style={{ textDecoration: "none" }}
            >
              {customer.FirstName} {customer.LastName}
            </Link>
          </td>
          <td>{customer.Email}</td>
          <td>{customer.Status === 1 ? "Active" : "In-Active"}</td>
        </tr>
      );
    });

  const pageCount = Math.ceil(userData.length / customerPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div id="main-content">
      <main className="dashBoard" id="main-wrapper">
        <div className="opacity_bg"></div>
        <div className="container-fluid">
          <div className="content">
            <div className="row">
              <DashBoardLeft />
              <div className="col-md-12 col-lg-9">
                <div className="card card-border-radius">
                  <div className="card-header position-relative">
                    <div className="toggle-icon-wrapper">
                      <button
                        className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                        id="navbar_humburger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="navbar_humburger"
                        data-bs-original-title="Toggle Navigation"
                        aria-label="Toggle Navigation"
                      >
                        <span className="navbar-toggle-icon">
                          <span className="toggle-line"></span>
                        </span>
                      </button>
                    </div>
                    <img
                      src={brandlogo}
                      width="585"
                      height="113"
                      alt="Veta & Association Logo"
                      className="logo"
                    />
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-6"></div>
                      <div className="col-6">
                        <input
                          type="text"
                          placeholder="search"
                          onChange={(event) => {
                            setSearchTerm(event.target.value);
                          }}
                          className="form-control float-right client_item client_search"
                        />
                      </div>
                    </div>
                    <div id="clients">
                      <div className="d-flex justify-content-center">
                        <p>Click on a client to upload</p>
                        <p
                          className="sortbtn-dashboard ml-2"
                          onClick={sortAlphabetically}
                        >
                          Sort
                        </p>
                      </div>
                      <div className="table-responsive">
                        <table
                          className="table table-vcenter"
                          id="dataTable_files"
                        >
                          <thead>
                            <tr>
                              <th
                                style={{ width: "60%", cursor: "pointer" }}
                                onClick={sortAlphabetically}
                              >
                                Client <span>&#8595;</span>
                                <span>&#8593;</span>
                              </th>
                              <th style={{ width: "20%", cursor: "pointer" }}>
                                Email <span>&#8595;</span>
                                <span>&#8593;</span>
                              </th>
                              <th style={{ width: "20%", cursor: "pointer" }}>
                                Status <span>&#8595;</span>
                                <span>&#8593;</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {displayClients}
                          </tbody>
                        </table>
                      </div>
                      <div className="table_nav">
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"paginationBttns"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"paginationDisabled"}
                          activeClassName={"paginationActive"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminDashBoard;
