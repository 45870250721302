import React, { useState, useEffect } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandlogo from "../../images/brandlogo.png";
import download from "../../images/Icon-download.png";
import viewIcon from "../../images/view.png";
import ReactPaginate from "react-paginate";
import { format } from "date-fns";
import noFile from "../../images/nofile.png";
import { getUserID, getUserType } from "../../utils/Common";
import back from "../../images/back.png";
import { useHistory } from "react-router-dom";
import { Buffer } from "buffer";
import { toast } from "react-toastify";

const CPAViewClientFilesFolderWise = ({ match }, props) => {
  let history = useHistory();
  const [pageNumber, setPageNumber] = useState(0);
  const [files, setFiles] = useState([]);
  const id = getUserID();
  let userType = getUserType();
  const [folderName, setFolderName] = useState("");
  const [fullName, setFullName] = useState("");
  const [userName, setUserName] = useState("");
  const [ascending, setAscending] = useState(false);
  const [filesToDownload, setFilesToDownload] = useState([]);

  const sortByDate = () => {
    if (ascending === true) {
      const sorted = files.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setFiles(sorted);
    } else {
      const sorted = files.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
      setFiles(sorted);
    }
  };

  const sortfiles = () => {
    setAscending(!ascending);
    sortByDate();
  };

  const getFiles = async () => {
    const details = await fetch(
      `https://vetacpav2.azurewebsites.net/api/file/cpa/view/all`,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          "folder-id": `${match.params.id}`,
          "user-id": id,
        },
      }
    );
    const data = await details.json();
    setFiles(data);
  };

  useEffect(() => {
    getFiles();

    const getFolderDetails = async () => {
      const details = await fetch(
        `https://vetacpav2.azurewebsites.net/api/folder/get/userdetails/${match.params.id}`,
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },
        }
      );
      const data = await details.json();
      setFolderName(data[0].FolderName);
      setFullName(
        `${data[0].user_info[0].FirstName} ${data[0].user_info[0].LastName}`
      );
      setUserName(data[0].user_info[0].UserName);
    };
    getFolderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectFiles = (e, value) => {
    if (!filesToDownload.includes(value)) {
      setFilesToDownload([...filesToDownload, value]);
    } else {
      const index = filesToDownload.indexOf(value);
      if (index > -1) {
        filesToDownload.splice(index, 1);
        setFilesToDownload(filesToDownload);
      }
    }
  };

  const downloadMultipleFiles = (e) => {
    e.preventDefault();
    if (filesToDownload.length <= 0) {
      toast.error("Please select the files which you need to download");
    } else {
      downloadMultipleFilesApi();
      toast.success("Loading Please Wait...!!");
    }
  };

  const downloadMultipleFilesApi = async () => {
    try {
      console.log("inside api call");
      const result = await fetch(
        `https://vetacpav2.azurewebsites.net/api/multidownload/${userType}/${id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({ filesToDownload }),
        }
      );
      // console.log(await result.json());
      // window.open(result, "_blank");
      const file = await result.blob();
      let url = window.URL.createObjectURL(file);
      let link = document.createElement("a");
      link.href = url;
      link.download = "Files-" + Date.parse(new Date()) + ".zip";
      link.click();
    } catch (error) {
      toast.error(error);
    }
  };

  const filesPerPage = 10;
  const pageVisited = pageNumber * filesPerPage;
  const pageCount = Math.ceil(files.length / filesPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayFiles = files
    .slice(pageVisited, pageVisited + filesPerPage)
    .map((file) => {
      const date = format(new Date(file.createdAt), "MM-dd-yyyy");
      const filename = Buffer.from(file.FileName).toString("base64");
      const clientusername = Buffer.from(userName).toString("base64");
      return (
        <tr>
          <td>
            <input
              type="checkbox"
              onClick={(e) => {
                selectFiles(e, file._id);
              }}
            />
          </td>
          <td style={{ wordWrap: "break-word", maxWidth: "20rem" }}>
            {file.FileName}
          </td>
          <td>{date}</td>
          <td>{file?.UploadedBy}</td>
          <td>
            <a
              href={`https://vetacpav2.azurewebsites.net/files/${file._id}/${userType}/${id}/${clientusername}/${file.FileName}`}
              type="button"
              className="downLoadFile"
              rel="noopener"
              title="view"
            >
              <span>
                <img
                  src={viewIcon}
                  alt="Icon-download"
                  width="24"
                  height="18"
                />
              </span>
            </a>
          </td>
          <td>{file.CPAView ? "YES" : "NO"}</td>
          <td>{file.ClientView ? "YES" : "NO"}</td>
        </tr>
      );
    });
  if (files.length <= 0) {
    return (
      <div id="main-content">
        <main className="dashBoard" id="main-wrapper">
          <div className="opacity_bg"></div>
          <div className="container-fluid">
            <div className="content">
              <div className="row">
                <DashBoardLeft />
                <div className="col-md-12 col-lg-9">
                  <div className="card card-border-radius">
                    <div className="card-header position-relative">
                      {/*button to toggle sidebar for smaller width*/}
                      <div className="toggle-icon-wrapper">
                        <button
                          className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                          id="navbar_humburger"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          title="navbar_humburger"
                          data-bs-original-title="Toggle Navigation"
                          aria-label="Toggle Navigation"
                        >
                          <span className="navbar-toggle-icon">
                            <span className="toggle-line"></span>
                          </span>
                        </button>
                      </div>

                      {/*Brand Logo Images*/}
                      <img
                        src={brandlogo}
                        width="585"
                        height="113"
                        alt="Veta & Association Logo"
                        className="logo"
                      />
                    </div>

                    <div className="card-body mx-auto">
                      <div className="row">
                        <div className="col-6"></div>
                        <div className="col-6 d-flex justify-content-end"></div>
                      </div>
                      <div className="row">
                        <div className="col-6 float-left">
                          <h4
                            style={{ color: "#940603" }}
                          >{`Home >>>  ${fullName} >>> ${folderName}`}</h4>
                        </div>
                        <div className="col-6">
                          <img
                            src={back}
                            className="float-right"
                            alt="back"
                            style={{ width: "10%", cursor: "pointer" }}
                            onClick={history.goBack}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mx-auto">
                          <h1 className="pb-2 b">No files were found..!!</h1>
                          <br />
                          <img
                            src={noFile}
                            className="img-fluid text-center"
                            style={{ width: "30%", marginLeft: "20rem" }}
                            alt="no-file"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  } else {
    return (
      <div id="main-content">
        <main className="dashBoard" id="main-wrapper">
          <div className="opacity_bg"></div>
          <div className="container-fluid">
            <div className="content">
              <div className="row">
                <DashBoardLeft />
                <div className="col-md-12 col-lg-9">
                  <div className="card card-border-radius">
                    <div className="card-header position-relative">
                      {/*button to toggle sidebar for smaller width*/}
                      <div className="toggle-icon-wrapper">
                        <button
                          className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                          id="navbar_humburger"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          title="navbar_humburger"
                          data-bs-original-title="Toggle Navigation"
                          aria-label="Toggle Navigation"
                        >
                          <span className="navbar-toggle-icon">
                            <span className="toggle-line"></span>
                          </span>
                        </button>
                      </div>

                      {/*Brand Logo Images*/}
                      <img
                        src={brandlogo}
                        width="585"
                        height="113"
                        alt="Veta & Association Logo"
                        className="logo"
                      />
                    </div>

                    <div className="card-body">
                      <div className="row justify-content-between">
                        <div className="col-6"></div>
                        <div className="col-6 d-flex justify-content-end">
                          <img
                            src={back}
                            alt="back"
                            style={{ width: "10%", cursor: "pointer" }}
                            onClick={history.goBack}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <h5
                            style={{ color: "#940603" }}
                          >{`Home >>>  ${fullName} >>> ${folderName}`}</h5>
                        </div>
                        <div className="col-6"></div>
                      </div>
                      <div className="row justify-content-between mt-2">
                        <div className="col-6"></div>
                        <div className="col-6 d-flex justify-content-end">
                          <button
                            onClick={(e) => {
                              downloadMultipleFiles(e);
                            }}
                          >
                            Download Selected Files
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table
                          className="table table-vcenter"
                          id="dataTable_files"
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th style={{ width: "20%" }}>File Name</th>
                              <th
                                style={{ width: "15%", cursor: "pointer" }}
                                onClick={sortfiles}
                              >
                                Date <span>&#8595;</span>
                                <span>&#8593;</span>
                              </th>
                              <th style={{ width: "10%" }}>Uploaded By</th>
                              <th style={{ width: "10%" }}>
                                View/ Download Documents
                              </th>
                              <th style={{ width: "10%" }}>CPA Viewed?</th>
                              <th style={{ width: "10%" }}>Client Viewed?</th>
                            </tr>
                          </thead>
                          <tbody>{displayFiles}</tbody>
                        </table>
                        <div className="table_nav">
                          <ReactPaginate
                            previousLabel="Previous"
                            nextLabel="Next"
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
};

export default CPAViewClientFilesFolderWise;
