export const getFirstName = () => {
  const userFirstName = sessionStorage.getItem("firstname");
  if (userFirstName) return userFirstName;
  else return null;
};

export const getLastName = () => {
  const userLastName = sessionStorage.getItem("lastname");
  if (userLastName) return userLastName;
  else return null;
};

export const getUserType = () => {
  const userType = sessionStorage.getItem("usertype");
  if (userType) return userType;
  else return null;
};

export const getUserName = () => {
  const userName = sessionStorage.getItem("username");
  if (userName) return userName;
  else return null;
};

export const getUserID = () => {
  const userID = sessionStorage.getItem("id");
  if (userID) return userID;
  else return null;
};

export const getToken = () => {
  const userToken = sessionStorage.getItem("token");
  if (userToken) return userToken;
  else return null;
};

export const getUserEmail = () => {
  const userEmail = sessionStorage.getItem("useremail");
  if (userEmail) return userEmail;
  else return null;
};

export const getOTP = () => {
  const otp = sessionStorage.getItem("otp");
  if (otp) return otp;
  else return null;
};

export const setUserName = (firstname, lastname) => {
  sessionStorage.setItem("firstname", firstname);
  sessionStorage.setItem("lastname", lastname);
};

export const removeUserName = () => {
  sessionStorage.removeItem("firstname");
  sessionStorage.removeItem("lastname");
};

export const setUserSession = (usertype, id, username, token) => {
  sessionStorage.setItem("usertype", usertype);
  sessionStorage.setItem("id", id);
  sessionStorage.setItem("username", username);
  sessionStorage.setItem("token", token);
};

export const forgetPassCredentials = (useremail, otp) => {
  sessionStorage.setItem("useremail", useremail);
  sessionStorage.setItem("otp", otp);
};

export const removeForgetPassCredentials = () => {
  sessionStorage.removeItem("useremail");
  sessionStorage.removeItem("otp");
};

export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("firstname");
  sessionStorage.removeItem("lastname");
  sessionStorage.removeItem("usertype");
  sessionStorage.removeItem("id");
  sessionStorage.removeItem("username");
};
