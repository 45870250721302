import React from "react";
import { getUserType } from "../../utils/Common";
import AdminDashBoard from "./AdminDashBoard";
import CustomerDashBoard from "./CustomerDashBoard";
const DashBoard = () => {
  const usertype = getUserType();

  if (usertype === "Customer") {
    return <CustomerDashBoard />;
  } else {
    return <AdminDashBoard />;
  }
};

export default DashBoard;
