import React, { useState, useEffect } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandLogo from "../../images/brandlogo.png";
import viewIcon from "../../images/view.png";
import { Link, useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import back from "../../images/back.png";

const CPAViewClient = () => {
  let fullname;
  const [searchTerm, setSearchTerm] = useState("");
  let history = useHistory();
  const [userDetails, setUserDetails] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [customerPerPage, setCustomerPerPage] = useState(10);
  const pageVisited = pageNumber * customerPerPage;
  const [sorting, setSorting] = useState(true);

  const sortAlphabetically = () => {
    if (sorting === true) {
      const sorted = userDetails.sort((a, b) => {
        return `${a.FirstName} ${a.LastName}`.localeCompare(
          `${b.FirstName} ${b.LastName}`
        );
      });
      setUserDetails(sorted);
      setSorting(false);
    } else {
      const sorted = userDetails.sort((a, b) => {
        return `${b.FirstName} ${b.LastName}`.localeCompare(
          `${a.FirstName} ${a.LastName}`
        );
      });
      setUserDetails(sorted);
      setSorting(true);
    }
  };

  const displayClients = userDetails
    .filter((customer) => {
      fullname = `${customer.FirstName} ${customer.LastName}`;
      if (searchTerm === "") {
        return customer;
      } else if (
        (customer = fullname.toLowerCase().includes(searchTerm.toLowerCase()))
      ) {
        return customer;
      } else {
        return customer;
      }
    })
    .slice(pageVisited, pageVisited + customerPerPage)
    .map((customer) => {
      return (
        <tr>
          <td>
            {customer.FirstName} {customer.LastName}
          </td>
          <td>{customer.Email}</td>
          <td>
            <Link to={`/cpa/viewfolders/${customer._id}`}>
              <img
                src={viewIcon}
                alt="view_icon"
                className="ml-3"
                width="21"
                height="18"
              />
            </Link>
          </td>
        </tr>
      );
    });

  const pageCount = Math.ceil(userDetails.length / customerPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const getClients = async () => {
      const details = await fetch(
        `https://vetacpav2.azurewebsites.net/api/customermaster/view/client`,
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },
        }
      );
      const data = await details.json();
      setUserDetails(data);
    };
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="main-content">
      <main className="dashBoard" id="main-wrapper">
        <div className="opacity_bg"></div>
        <div className="container-fluid">
          <div className="content">
            <div className="row">
              <DashBoardLeft />
              <div className="col-md-12 col-lg-9">
                <div className="card card-border-radius">
                  <div className="card-header position-relative">
                    <div className="toggle-icon-wrapper">
                      <button
                        className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                        id="navbar_humburger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="navbar_humburger"
                        data-bs-original-title="Toggle Navigation"
                        aria-label="Toggle Navigation"
                      >
                        <span className="navbar-toggle-icon">
                          <span className="toggle-line"></span>
                        </span>
                      </button>
                    </div>
                    <img
                      src={brandLogo}
                      width="585"
                      height="113"
                      alt="Veta & Association Logo"
                      className="logo"
                    />
                  </div>
                  <div className="card-body">
                    <div className="row justify-content-between">
                      <div className="col-4">
                        <input
                          type="text"
                          placeholder="search"
                          onChange={(event) => {
                            setSearchTerm(event.target.value);
                          }}
                          className="form-control float-left client_item client_search"
                        />
                      </div>
                      <div className="col-3">
                        <label>No Of Clients Per Page</label>
                        <select
                          name="customerPerPage"
                          className="form-control"
                          onChange={(e) => {
                            setCustomerPerPage(e.currentTarget.value);
                          }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                        </select>
                      </div>
                      <div className="col-5">
                        <img
                          src={back}
                          alt="back"
                          className="float-right"
                          style={{ width: "10%", cursor: "pointer" }}
                          onClick={history.goBack}
                        />
                      </div>
                    </div>
                    <div className="d-block"></div>
                    <div className="table-responsive">
                      <table
                        className="table table-vcenter"
                        id="dataTable_files"
                      >
                        <thead>
                          <tr>
                            <th
                              style={{ width: "20%", cursor: "pointer" }}
                              onClick={sortAlphabetically}
                            >
                              Client <span>&#8595;</span>
                              <span>&#8593;</span>
                            </th>
                            <th style={{ width: "20%" }}>Client Email</th>
                            <th style={{ width: "20%" }}>Folders</th>
                          </tr>
                        </thead>
                        <tbody>{displayClients}</tbody>
                      </table>
                    </div>
                    <div className="table_nav">
                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CPAViewClient;
