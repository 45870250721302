import React from "react";
import { Route, Switch } from "react-router";
import PrivateRoute from "../utils/PrivateRoute";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import DashBoard from "./Pages/DashBoard";
import CustomerEditProfile from "./Pages/CustomerEditProfile";
import AdminManageClients from "./Pages/AdminManageClients";
import AdminEditSingleClient from "./Pages/AdminEditSingleClient";
import AdminAddClient from "./Pages/AdminAddClient";
import AdminSettings from "./Pages/AdminSettings";
import AdminEditProfile from "./Pages/AdminEditProfile";
import AdminManageCPA from "./Pages/AdminManageCPA";
import AdminEditSingleCPA from "./Pages/AdminEditSingleCPA";
import AdminAddCPA from "./Pages/AdminAddCPA";
import CustomerViewFolder from "./Pages/CustomerViewFolder";
import CustomerCreateNewFolder from "./Pages/CustomerCreateNewFolder";
import CustomerUploadFile from "./Pages/CustomerUploadFile";
import CustomerUploadFileMain from "./Pages/CustomerUploadFileMain";
import CustomerViewFiles from "./Pages/CustomerViewFiles";
import CpaEditProfile from "./Pages/CpaEditProfile";
import AdminUploadFileModal from "./Pages/AdminUploadFileModal";
import AdminViewFiles from "./Pages/AdminViewFiles";
import AdminViewFoldersOfClient from "./Pages/AdminViewFoldersOfClient";
import AdminViewFilesFolderWise from "./Pages/AdminViewFilesFolderWise";
import CPAViewClient from "./Pages/CPAViewClient";
import CPAViewClientFolders from "./Pages/CPAViewClientFolders";
import CPAViewClientFilesFolderWise from "./Pages/CPAViewClientFilesFolderWise";
import CustomerViewFilesFolderWise from "./Pages/CustomerViewFilesFolderWise";
import ForgetPassword from "./Pages/ForgetPassword";
import ChangePassword from "./Pages/ChangePassword";
import AdminCreateFolder from "./Pages/AdminCreateFolder";
import EditFolderName from "./Pages/EditFolderName";
import CPAEditFolderName from "./Pages/CPAEditFolderName";

const MainRouter = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Login}></Route>
        <Route exact path="/signup" component={SignUp}></Route>
        <Route exact path="/home" component={DashBoard}></Route>
        <PrivateRoute
          exact
          path="/editprofile"
          component={CustomerEditProfile}
        />
        <PrivateRoute exact path="/addcpa" component={AdminAddCPA} />
        <PrivateRoute exact path="/managecpa" component={AdminManageCPA} />
        <PrivateRoute
          exact
          path="/managecpa/:id"
          component={AdminEditSingleCPA}
        />
        <PrivateRoute
          exact
          path="/manageclients"
          component={AdminManageClients}
        />
        <PrivateRoute
          exact
          path="/manageclient/:id"
          component={AdminEditSingleClient}
        />
        <PrivateRoute exact path="/addclient" component={AdminAddClient} />
        <PrivateRoute exact path="/settings" component={AdminSettings} />
        <PrivateRoute
          exact
          path="/editadminprofile"
          component={AdminEditProfile}
        />
        <PrivateRoute
          exact
          path="/foldermanagement"
          component={CustomerViewFolder}
        />
        <PrivateRoute
          exact
          path="/createnewfolder"
          component={CustomerCreateNewFolder}
        />
        <PrivateRoute
          exact
          path="/uploadfiles"
          component={CustomerUploadFile}
        />
        <PrivateRoute
          exact
          path="/uploadfiles/clientuploadfile/:fileid/:cpaid"
          component={CustomerUploadFileMain}
        />
        <PrivateRoute
          exact
          path="/adminuploadfile/:id"
          component={AdminUploadFileModal}
        />
        <PrivateRoute exact path="/viewfiles" component={CustomerViewFiles} />
        <PrivateRoute exact path="/editcpaprofile" component={CpaEditProfile} />
        <PrivateRoute exact path="/adminviewfiles" component={AdminViewFiles} />
        <PrivateRoute
          exact
          path="/admin/manageclient/viewfolders/:id"
          component={AdminViewFoldersOfClient}
        />
        <PrivateRoute
          exact
          path="/files/:id"
          component={AdminViewFilesFolderWise}
        />
        <PrivateRoute
          exact
          path="/admin/manageclient/viewfolders/edit/:id"
          component={EditFolderName}
        />
        <PrivateRoute
          exact
          path="/cpa/viewfolders/edit/:id"
          component={CPAEditFolderName}
        />
        <PrivateRoute exact path="/cpa/viewclients" component={CPAViewClient} />
        <PrivateRoute
          exact
          path="/cpa/viewfolders/:id"
          component={CPAViewClientFolders}
        />
        <PrivateRoute
          exact
          path="/cpa/files/:id"
          component={CPAViewClientFilesFolderWise}
        />
        <PrivateRoute
          exact
          path="/client/folder/files/:id"
          component={CustomerViewFilesFolderWise}
        />
        <PrivateRoute
          exact
          path="/admincreatefolder/:id"
          component={AdminCreateFolder}
        />
        <Route exact path="/forgetpassword" component={ForgetPassword} />
        <Route exact path="/changepassword" component={ChangePassword} />
      </Switch>
    </>
  );
};

export default MainRouter;
