import React, { useState, useEffect } from "react";
import DashBoardLeft from "../LeftMainComponent/DashBoardLeft";
import brandlogo from "../../images/brandlogo.png";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { getToken } from "../../utils/Common";
import back from "../../images/back.png";
function CPAEditFolderName({ match }, props) {
  let history = useHistory();
  const token = getToken();
  const [folderData, setFolderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    setFolderData({ ...folderData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const getFolderDetails = async () => {
      const details = await fetch(
        `https://vetacpav2.azurewebsites.net/api/folder/editfoldername/${match.params.id}`,
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },
        }
      );
      const data = await details.json();
      setFolderData(data);
    };
    getFolderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formHandler = (e) => {
    e.preventDefault();
    if (folderData.FolderName.length <= 3) {
      toast.error("Folder name should be of more than 3 characters");
    } else {
      setLoading(true);
      updateFolder();
    }
  };

  const updateFolder = async () => {
    await fetch(
      `https://vetacpav2.azurewebsites.net/api/folder/editfoldername/${match.params.id}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "auth-token": token,
          "content-type": "application/json",
        },
        body: JSON.stringify(folderData),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message) {
          setLoading(false);
          toast.error(responseJson.message);
        } else {
          setLoading(false);
          toast.success(responseJson.success);
          history.goBack();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };
  return (
    <div id="main-content">
      <main className="dashBoard" id="main-wrapper">
        <div className="opacity_bg"></div>
        <div className="container-fluid">
          <div className="content">
            <div className="row">
              <DashBoardLeft />
              <div className="col-md-12 col-lg-9">
                <div className="card card-border-radius">
                  <div className="card-header position-relative">
                    <div className="toggle-icon-wrapper">
                      <button
                        className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                        id="navbar_humburger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="navbar_humburger"
                        data-bs-original-title="Toggle Navigation"
                        aria-label="Toggle Navigation"
                      >
                        <span className="navbar-toggle-icon">
                          <span className="toggle-line"></span>
                        </span>
                      </button>
                    </div>
                    <img
                      src={brandlogo}
                      width="585"
                      height="113"
                      alt="Veta & Association Logo"
                      className="logo"
                    />
                  </div>

                  <div className="card-body">
                    <div className="row justify-content-between">
                      <div className="col-6"></div>
                      <div className="col-6 d-flex justify-content-end">
                        <img
                          src={back}
                          alt="back"
                          style={{ width: "10%", cursor: "pointer" }}
                          onClick={history.goBack}
                        />
                      </div>
                    </div>
                    <h4 className="text-center">Edit Folder Name &nbsp;</h4>
                    <br />
                    <form id="edit_profile_form" onSubmit={formHandler}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group position-relative">
                            <label for="username">Folder Name</label>
                            <input
                              type="text"
                              name="FolderName"
                              id="foldername"
                              className="form-control"
                              placeholder="Enter new folder name"
                              value={folderData.FolderName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <button
                        id="updateUserBtn"
                        className="btn btn-block button-primary"
                        disabled={loading ? true : false}
                      >
                        {loading ? "Loading..." : "Update"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default CPAEditFolderName;
